import { createContext, useState, useEffect, useContext } from 'react';
import { useAppStore } from '../App';


const PretestContext = createContext();

export function usePretest() {
    return useContext(PretestContext);
}

export function PretesProvider(props) {

    const [answers, setAnswers] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState[0];
    const pretestData = useAppStore(state => state.pretestData);

    const reset = () => {
        setAnswers([])
        setCurrentQuestionIndex(0)
    }

    useEffect(() => {
        console.log(JSON.setAnswers(answers))
    }, [answers])

    const answerCurrentPretest = (answerChoiceID) => {
        const newAnswers = [...answers, answerChoiceID];
        setAnswers(newAnswers)
    }

    const back = () => {
        var oldAnswers = [...answers];
        oldAnswers.pop();
        const newAnswers = [...oldAnswers];
        setAnswers(newAnswers)
    }

    const value = {
        answers,
        reset,
        pretestData,
        answerCurrentPretest,
        back
    }

    return (
        <PretestContext.Provider value={value}>
            {props.children}
        </PretestContext.Provider>
    );
}

export default PretestContext;