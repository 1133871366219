import CryptoJS from 'crypto-js';
import moment from 'moment';

const bridge = "cft2021";

export const encrypt = (value) => {
    const timestamp = moment().format();

    const encrypt = CryptoJS.AES.encrypt(value, timestamp)

    return `${encrypt}${bridge}${timestamp}`;
}

export const decrypt = (value) => {

    const arr = value.split(bridge);
    if (arr.length !== 2) {
        return ""
    }

    const bytes = CryptoJS.AES.decrypt(arr[0], arr[1])
    var decrypt = bytes.toString(CryptoJS.enc.Utf8);

    return decrypt
}