import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './App.css';
import Pretest from './threejs/scenes/Pretest';
import 'antd/dist/antd.css';

import MainMenu from './threejs/scenes/MainMenu';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { Stage1 } from './threejs/scenes/Stage1';
import { Stage2 } from './threejs/scenes/Stage2';
import StageTemplate from './components/StageTemplate';
import GameOverPage from './components/GameOverPage';
import { Stage3 } from './threejs/scenes/Stage3';
import Login from './threejs/scenes/Login';
import Register from './threejs/scenes/Register';
import { AuthProvider } from './context/AuthContext';
import { TestStage } from './threejs/scenes/TestStage';
import { Stage4 } from './threejs/scenes/Stage4';
import { Stage5 } from './threejs/scenes/Stage5';
import ExportTestResultsPage from './threejs/scenes/ExportTestResultsPage';

ReactDOM.render(
  <BrowserRouter>
    <Routes >
      <Route path="/" element={<AuthProvider><App /></AuthProvider>} >
        <Route path="register" element={<Register />} />
        <Route path="login" element={<Login />} />
        <Route path="pretest/:isposttest" element={<Pretest />} />
        <Route path="menu" element={<MainMenu />} />
        <Route path="gameover" element={<GameOverPage />} />
        <Route path="stage1" element={
          <StageTemplate key="stage1template" stageID={1} ingameRefScale={[1.6, 1.2, 1]}>
            {(stageData, onAnswered) => [<Stage1 gameData={stageData} onAnswered={onAnswered} />]}
          </StageTemplate>} />
        <Route path="stage2" element={
          <StageTemplate key="stage2template" stageID={2} ingameRefScale={[1.6, 1.2, 1]}>
            {(stageData, onAnswered) => [<Stage2 gameData={stageData} onAnswered={onAnswered} />]}
          </StageTemplate>} />
        <Route path="stage3" element={
          <StageTemplate key="stage3template" stageID={3} ingameRefScale={[1.6, 1.2, 1]}>
            {(stageData, onAnswered) => [<Stage3 gameData={stageData} onAnswered={onAnswered} />]}
          </StageTemplate>} />
        <Route path="stage4" element={
          <StageTemplate key="stage4template" stageID={4} ingameRefScale={[1.6, 1.2, 1]}>
            {(stageData, onAnswered) => [<Stage4 gameData={stageData} onAnswered={onAnswered} />]}
          </StageTemplate>} />
        <Route path="stage5" element={
          <StageTemplate key="stage5template" stageID={5} ingameRefScale={[1.6, 1.2, 1]}>
            {(stageData, onAnswered) => [<Stage5 gameData={stageData} onAnswered={onAnswered} />]}
          </StageTemplate>} />
        <Route path="stage6" element={
          <StageTemplate key="stage6template" stageID={6} totalQuestionsPerGameStage={1} ingameRefScale={[1.6, 1.2, 1]}>
            {(stageData, onAnswered, totalQuestionsPerGameStage) => [<Stage1 gameData={stageData} onAnswered={onAnswered} totalQuestionsPerGameStage={totalQuestionsPerGameStage} />,
            <Stage2 gameData={stageData} onAnswered={onAnswered} totalQuestionsPerGameStage={totalQuestionsPerGameStage} />,
            <Stage3 gameData={stageData} onAnswered={onAnswered} totalQuestionsPerGameStage={totalQuestionsPerGameStage} />,
            <Stage4 gameData={stageData} onAnswered={onAnswered} totalQuestionsPerGameStage={totalQuestionsPerGameStage} />,
            <Stage5 gameData={stageData} onAnswered={onAnswered} totalQuestionsPerGameStage={totalQuestionsPerGameStage} />]}
          </StageTemplate>} />
        <Route path="teststage" element={
          <TestStage></TestStage>
        } />
        <Route path="export" element={
          <ExportTestResultsPage></ExportTestResultsPage>
        } />
      </Route>
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
