/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import React, { Suspense, useEffect, useRef, useState, memo } from 'react'
import { Text, useGLTF, Html, Billboard, OrbitControls, Environment, Float, Loader, Preload, ContactShadows, Image, Center, Bounds, useBounds, Box } from '@react-three/drei'
import * as THREE from "three"
import { MeshPhongMaterial, MeshBasicMaterial } from 'three'
import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { useSpring, animated } from '@react-spring/three'
import { InteractionsContext, Interactive } from '@react-three/xr'
import { useFrame } from '@react-three/fiber'
import { MeshStandardMaterial } from 'three'

const dracoLoader = new DRACOLoader();

export const getCounterBaseType = (type = "a", isMRNA = false) => {

    var counterType = "";

    switch (type) {
        case "a":
            counterType = isMRNA ? "u" : "t";
            break;
        case "c":
            counterType = "g";
            break;
        case "g":
            counterType = "c";
            break;
        case "t":
        case "u":
            counterType = "a";
            break;
        default:
            counterType = isMRNA ? "u" : "t";
            break;
    }

    return counterType;
}
const DNABase = React.memo((props) => {
    const { label = "a", shape = undefined, showLabel = true, onClick = () => { }, split, opacity = 1, emissiveIntensity = 1, position = [0, 0.12, 0], rotation = [0, 0, 0] } = props

    const baseShape = shape ? shape : label
    const [thisGeom, setThisGeom] = useState();
    const [thisMat, setThisMat] = useState();
    const [thisFocusMat, setThisFocusMat] = useState();
    var type = baseShape;

    switch (baseShape) {
        case "a":
            type = "a";
            break;
        case "c":
            type = "c";
            break;
        case "g":
            type = "g";
            break;
        case "t":
        case "u":
            type = "t";
            break;
        default:
            type = "a";
            break;
    }


    const groupRef = useRef()
    const meshRef = useRef()
    const { nodes, materials } = useLoader(GLTFLoader, `/3D/dna_${type}_model.glb`, loader => {
        dracoLoader.setDecoderPath('/draco/');
        loader.setDRACOLoader(dracoLoader);
    })
    const [mat, setMat] = useState(null)
    const [distance, setDistance] = useState(0)
    const [baseSplit, setBaseSplit] = useState(false)
    const [matOpacity, setMatOpacity] = useState(0)
    const [labelVisible, setLabelVisible] = useState(true)


    const isVisible = opacity > 0;
    useEffect(() => {
        setBaseSplit(!baseSplit)
    }, [split])

    useEffect(() => {
        setLabelVisible(showLabel)
        // console.log("showLabel " + showLabel)

        if (opacity !== matOpacity)
            setMatOpacity(opacity)
    }, [props])

    useEffect(() => {
        // console.log("props " + JSON.stringify(props.position))

        if (opacity !== matOpacity)
            setMatOpacity(opacity)
    }, [])

    // const a_vertices = new Float32Array([
    //     -0.005, 0.0, 0.0,
    //     0.005, 0.0, 0.0,
    //     0.005, 0.1, 0.0,

    //     0.1, 0.0, 0.0,
    //     0.1, 0.1, 0.0,
    //     0.0, 0.1, 0.0
    // ]);
    return (
        <Suspense fallback={null}>
            <Interactive onSelect={() => {
                onClick();
            }}>

                <animated.group ref={groupRef} {...props} dispose={null} onClick={() => {
                    onClick();
                }}>
                    <mesh ref={meshRef} geometry={nodes[`dna_${type}`].geometry} renderOrder={1} position={[0, -0.0015, 0]}>

                        <animated.meshPhongMaterial key={`mat_${label}`} map={materials[`dna_${type}_mat`].map} attach="material" opacity={matOpacity} transparent />
                    </mesh>
                    {/* <mesh>
                        <sphereBufferGeometry args={[0.05, 8, 8]} attach="geometry" />
                        <meshStandardMaterial color={0xff0000} attach="material" />
                    </mesh> */}
                    {/* <mesh>
                        <bufferGeometry>
                            <bufferAttribute
                                attachObject={["attributes", "position"]}
                                array={a_vertices}
                                itemSize={3}
                                count={6}
                            />
                        </bufferGeometry>
                        <meshStandardMaterial attach="material" color="hotpink" flatShading={true} />
                    </mesh> */}
                    {labelVisible ? (
                        <>
                            <Text onClick={() => {
                                onClick();
                            }} position={[0, -0.08, 0.015]} renderOrder={2} fontSize={0.06} color="#000" anchorX="center" anchorY="middle" transparent>
                                {label}
                                <animated.meshStandardMaterial color={"black"} attach="material" opacity={matOpacity} transparent />
                            </Text>
                            <Text onClick={() => {
                                onClick();
                            }} position={[0, -0.08, -0.015]} renderOrder={3} rotation={[Math.PI, 0, 0]} fontSize={0.06} color="#000" anchorX="center" anchorY="middle">
                                {label}
                                <animated.meshStandardMaterial color={"black"} attach="material" opacity={matOpacity} transparent />
                            </Text>
                        </>) : null}
                </animated.group>
            </Interactive>
        </Suspense >
    )
})
// const DNABase = React.memo((props) => {
//     const { label = "a", shape = undefined, showLabel = true, onClick = () => { }, split, opacity = 1, emissiveIntensity = 1, position = [0, 0.12, 0], rotation = [0, 0, 0] } = props

//     const baseShape = shape ? shape : label
//     var type = baseShape;

//     switch (baseShape) {
//         case "a":
//             type = "a";
//             break;
//         case "c":
//             type = "c";
//             break;
//         case "g":
//             type = "g";
//             break;
//         case "t":
//         case "u":
//             type = "t";
//             break;
//         default:
//             type = "a";
//             break;
//     }


//     const groupRef = useRef()
//     const meshRef = useRef()
//     const { nodes, materials } = useGLTF(`/3D/dna_${type}_model.glb`)
//     const [mat, setMat] = useState(null)
//     const [distance, setDistance] = useState(0)
//     const [baseSplit, setBaseSplit] = useState(false)
//     const [matOpacity, setMatOpacity] = useState(0)
//     const [labelVisible, setLabelVisible] = useState(true)


//     const isVisible = opacity > 0;
//     useEffect(() => {
//         setBaseSplit(!baseSplit)
//     }, [split])

//     useEffect(() => {
//         setLabelVisible(showLabel)
//         console.log("showLabel " + showLabel)

//         if (opacity !== matOpacity)
//             setMatOpacity(opacity)
//     }, [props])



//     useEffect(() => {
//         console.log("props " + JSON.stringify(props.position))

//         if (opacity !== matOpacity)
//             setMatOpacity(opacity)
//     }, [])

//     return (
//         <Suspense fallback={null}>
//             <Interactive onSelect={() => {
//                 onClick();
//             }}>
//                 <animated.group ref={groupRef} {...props} dispose={null} onClick={() => {
//                     onClick();
//                 }}>
//                     <mesh ref={meshRef} geometry={nodes[`dna_${type}`].geometry} renderOrder={1} position={[0, -0.0015, 0]}>
//                         <animated.meshStandardMaterial key={`mat_${label}`} map={materials[`dna_${type}_mat`].map} attach="material" opacity={matOpacity} transparent />
//                     </mesh>

//                     {labelVisible ? (
//                         <>
//                             <Text onClick={() => {
//                                 onClick();
//                             }} position={[0, -0.08, 0.015]} renderOrder={2} fontSize={0.06} color="#000" anchorX="center" anchorY="middle" transparent>
//                                 {label}
//                                 <animated.meshStandardMaterial color={"black"} attach="material" opacity={matOpacity} transparent />
//                             </Text>
//                             <Text onClick={() => {
//                                 onClick();
//                             }} position={[0, -0.08, -0.015]} renderOrder={3} rotation={[Math.PI, 0, 0]} fontSize={0.06} color="#000" anchorX="center" anchorY="middle">
//                                 {label}
//                                 <animated.meshStandardMaterial color={"black"} attach="material" opacity={matOpacity} transparent />
//                             </Text>
//                         </>) : null}
//                 </animated.group>
//             </Interactive>
//         </Suspense >
//     )
// })

export const DNAPair = React.memo((props) => {
    const label1Ref = useRef();
    const { position = [0, 0, 0], rotation = [0, 0, 0], scale = 1, split = 0, top = {}, bottom = {}, onClick = () => { }, index = 1, opacity, isLast = false, isFirst = false, showLabel = true } = props
    const maxSplitDistance = 0.15
    const groupRef = useRef();
    const [groupPosition, setGroupPosition] = useState([...position])
    const [groupRotation, setGroupRotation] = useState([...rotation])
    const [topOpacity, setTopOpacity] = useState(1)
    const [bottomOpacity, setBottomOpacity] = useState(1)
    const [groupScale, setGroupScale] = useState(scale)
    const [labelRotation, setLabelRotation] = useState([...rotation])
    const [meshOpacity, setMeshOpacity] = useState(1)
    const [active, setActive] = useState(false);
    const [topSplit, setTopSplit] = useState(top.split);
    const [bottomSplit, setBottomSplit] = useState(bottom.split);
    const groupProps = useSpring({ position: groupPosition, rotation: groupRotation, scale: active ? groupScale * 1.2 : groupScale })
    const labelProps = useSpring({ rotation: labelRotation })
    const topProps = useSpring({ position: [0, topSplit * maxSplitDistance, 0] })
    const topMatProps = useSpring({ emissiveIntensity: active ? 100 : 1, opacity: Math.min(opacity, topOpacity) })
    const bottomProps = useSpring({ position: [0, -bottomSplit * maxSplitDistance, 0] })
    const bottomMatProps = useSpring({ emissiveIntensity: active ? 100 : 1, opacity: Math.min(opacity, bottomOpacity) })


    useEffect(() => {
        console.log("rerendered")
    }, [])



    useEffect(() => {
        const { opacity = 1, scale = 1, position = [0, 0, 0], rotation = [0, 0, 0], active = false } = props
        setMeshOpacity(opacity)
        setGroupScale(scale)
        setGroupPosition(position)
        setGroupRotation(rotation)
        setLabelRotation([-rotation.x, -rotation.y, -rotation.z])
        setActive(active)
    }, [props])

    useEffect(() => {
        const { split = 0, opacity = 1 } = top
        if (split !== topSplit)
            setTopSplit(split)
        if (opacity !== topOpacity)
            setTopOpacity(opacity)
    }, [top])

    useEffect(() => {
        const { split = 0, opacity = 1 } = bottom

        if (split !== bottomSplit)
            setBottomSplit(split)
        if (opacity !== bottomOpacity)
            setBottomOpacity(opacity)
    }, [bottom])

    return (
        <Suspense fallback={null}>
            <animated.group ref={groupRef} {...groupProps} dispose={true} renderOrder={4} onClick={() => { }}>



                <>
                    {top.visible ? (<>
                        {isFirst ? (<Text ref={label1Ref} position={[-0.12, 0.13, 0]} anchorX="right" font="/fonts/ttf/fc_condensed.ttf" fontSize={0.10} color="black" >{(top.firstLabel !== undefined) ? top.firstLabel : "5'"}</Text>) : null}
                        <DNABase key={`dnapair_${index}_base_top`} label={top.type} showLabel={showLabel} {...topProps} {...topMatProps} rotation={[Math.PI, 0, 0]} onClick={() => onClick("top")}></DNABase>
                        {isLast ? (<Text position={[0.12, 0.13, 0]} anchorX="left" font="/fonts/ttf/fc_condensed.ttf" fontSize={0.10} color="black">{(top.endLabel !== undefined) ? top.endLabel : "3'"}</Text>) : null}
                    </>) : null}

                    {bottom.visible ? (<>
                        {isFirst ? (<Text position={[-0.12, -0.13, 0]} anchorX="right" font="/fonts/ttf/fc_condensed.ttf" fontSize={0.10} color="black">{(bottom.firstLabel !== undefined) ? bottom.firstLabel : "3'"}</Text>) : null}
                        <DNABase key={`dnapair_${index}_base_bottom`} label={bottom.type} showLabel={showLabel} {...bottomProps} {...bottomMatProps} rotation={[0, 0, 0]} onClick={() => onClick("top")}></DNABase>
                        {isLast ? (<Text position={[0.12, -0.13, 0]} anchorX="left" font="/fonts/ttf/fc_condensed.ttf" fontSize={0.10} color="black">{(bottom.endLabel !== undefined) ? bottom.endLabel : "5' สายแม่แบบ"}</Text>) : null}
                    </>) : null}
                </>

            </animated.group>

        </Suspense >
    )
})

/**
 * 
 * @param {array} letterSet 
 * @param {int} sequenceLength 
 * @param {array} excludeSequences 
 * @param {int} attempts 
 * @returns {*} resultSequence
 */
export const getRandomSequence = (letterSet = [], sequenceLength = 3, excludeSequences = [], attempts = 0) => {

    const sequence = [];

    if (attempts > 5)
        return null;

    do {

        for (var i = 0; i < sequenceLength; i++) {
            const pickedLetter = letterSet[Math.floor(Math.random() * letterSet.length)];
            sequence.push(pickedLetter)
        }
    }
    while (sequence.length < sequenceLength)

    if (excludeSequences.includes(sequence)) {
        return getRandomSequence(letterSet, sequenceLength, excludeSequences, attempts + 1)
    }
    else {
        return sequence;
    }
}

export const TRNA = ({ sequence = ["u", "a", "g"], onClick = () => { }, position = [0, 0, 0] }) => {

    const seqString = JSON.stringify(sequence);
    return (
        <Suspense fallback={null}>
            <Interactive onSelect={(event) => {
                onClick(sequence)
            }}>

                <animated.group position={position} scale={1} onClick={(event) => {
                    event.stopPropagation();
                    onClick(sequence)
                }}>

                    <Box key={`trna${seqString}`} name={`trna${seqString}`} position={[0, 0.2, 0]} args={[0.36, 0.12, 0.12]}>
                        <animated.meshPhongMaterial key={`trnaMat_${seqString}`} color="blue" opacity={1} transparent  ></animated.meshPhongMaterial>
                    </Box>

                    {
                        sequence.map((type, index) => {
                            return (
                                <DNABase key={`trna_${index}_type`} position={[-0.12 + index * 0.12, 0, 0]} label={type} showLabel={true} rotation={[Math.PI, 0, 0]} onClick={() => onClick(sequence)}></DNABase>
                            )
                        })
                    }

                </animated.group>
            </Interactive>
        </Suspense>
    )
}

export const ExonIntronBox = ({ index = 0, size = 1, position = [0, 0, 0], isIntron = false, opacity = 1, selectedIndex = [], onClick = () => { } }) => {

    const iSize = size * 0.12;
    const intronSize = [iSize, 0.1, 0.05]

    const intronColor = selectedIndex.includes(index) ? "#52D6DB" : "#1FABC2"
    const exonColor = selectedIndex.includes(index) ? "#F1DB55" : "#EDAF3C"

    const boxProps = useSpring({ color: isIntron ? intronColor : exonColor, opacity: opacity, transparent: true })
    console.log("args" + JSON.stringify(intronSize));

    const isVisible = opacity > 0;
    return <>
        <Suspense fallback={null}>
            <Interactive key={`intronExonTextMatGroup_${index}`} onSelect={(event) => {

                // console.log("event " + event)
                // console.log(isIntron ? "Intron " : "Exxon")
                onClick(isIntron, index)
            }}>

                <animated.group position={[position[0], position[1], position[2]]} scale={isVisible ? 1 : 0} onClick={(event) => {
                    if (event)
                        event.stopPropagation();
                    onClick(isIntron, index)
                }}>
                    <Box key={`intron${index}`} name={`intron${index}`} args={intronSize} renderOrder={index} >
                        <animated.meshPhongMaterial key={`intronExonMat_${index}`} {...boxProps} ></animated.meshPhongMaterial>
                    </Box>

                    <Text key={`intron_label_front_${index}`} position={[0, -0.008, 0.026]} font="/fonts/ttf/fc_condensed.ttf" renderOrder={index} fontSize={0.08} color="black" onClick={(event) => {
                        if (event)
                            event.stopPropagation();
                        onClick(isIntron, index)
                    }}>
                        {isIntron ? "INTRON" : "EXON"}
                        <animated.meshStandardMaterial key={`intronExonTextMat_${index}`} color={"black"} attach="material" opacity={opacity} transparent depthWrite={false} />
                    </Text>
                </animated.group>
            </Interactive>
        </Suspense>
    </>
}
export const DNAGameString = (props) => {

    const { isRNA = false, isCodon = false, nonSpinRange = [0, 0], mArray = [], scale = [1, 1, 1], position = [0, 0, 0], topFirstLabel, topEndLabel, bottomFirstLabel, bottomEndLabel, rotationStep = 0, toHideTop = [], topVisible = true, bottomVisible = true, toHideBottom = [], showLabel = true, toHidePair = [], inactivePair = [], enablePairSelection = false, selectedPairs = [], toHideGroup = [], onGroupClicked = () => { }, onBaseClicked = () => { }, intronGroups = [] } = props;
    const [toSplit, setToSplit] = useState([])
    const bounds = useBounds();
    const [groupRotation, setGroupRotation] = useState(0)
    const groupProps = useSpring({ rotation: [groupRotation, 0, 0], scale: scale, position: position })
    const [selectedExonIntronIndex, setSelectedExonIntronIndex] = useState([]);
    const [toHideBase, setToHideBase] = useState([]);
    const [inactiveBase, setInactiveBase] = useState([]);
    const [firstIndex, setFirstIndex] = useState(1);
    const [lastIndex, setLastIndex] = useState(1);
    const [mBaseArray, setMBaseArray] = useState([]);
    const [toIHideGroup, setToIHideGroup] = useState(toHideGroup);

    var result = [];
    var currentSpin = 0;
    var groupSpin = 0;


    var lastPaiPosX = 0;


    useEffect(() => {
        if (JSON.stringify(toHideGroup) !== JSON.stringify(toIHideGroup))
            setToIHideGroup(toHideGroup)
    }, [toHideGroup])
    const findFirstIndex = (targetArray, hidseList) => {
        var index = -1

        console.log("targetArray.legnth " + targetArray.length)
        for (var i = 0; i < targetArray.length; i++) {
            if (!hidseList.includes(i)) {
                index = i
                break;
            }
        }
        return index;
    }
    const findLastIndex = (targetArray, hidseList) => {
        var index = -1
        for (var i = targetArray.length - 1; i >= 0; i--) {

            console.log("i " + i)
            console.log("toHideBase " + hidseList)
            console.log("match " + hidseList.includes(i))
            if (!hidseList.includes(i)) {
                index = i
                break;
            }
        }
        return index;
    }


    useEffect(() => {

        //check if has to be in group mode
        if (intronGroups && intronGroups.length) {
            var count = 0;

            var toHideBasePairs = []


            intronGroups.forEach((groupLength, index) => {

                if (toHideGroup.includes(index)) {

                    for (var i = count; i < count + groupLength; i++) {
                        toHideBasePairs.push(i)
                    }
                }


                count += groupLength;
            })

            console.log("toHideBasePairs " + toHideBasePairs)
            setToHideBase([...toHideBasePairs])
        }
        else {
            setToHideBase([...toHidePair])
            setInactiveBase([...inactivePair]);
        }

        setMBaseArray(mArray)

    }, [props])

    const renderCodonProps = (sequence = [], tohideIndices = []) => {
        const codonsResult = [];

        for (var i = 0; i < Math.floor(sequence.length / 3); i++) {
            const startIndex = i * 3;
            var shouldShow = !(tohideIndices.includes(startIndex) || tohideIndices.includes(startIndex + 1) || tohideIndices.includes(startIndex + 2))
            codonsResult.push(
                <Suspense fallback={null}>
                    <animated.group position={[(i * 0.36) + 0.12, 0.2, 0]} scale={shouldShow ? 1 : 0}>
                        <Box key={`codon${i}`} name={`codon${i}`} args={[0.36, 0.12, 0.12]} renderOrder={i} >
                            <animated.meshPhongMaterial key={`codonMat_${i}`} color="blue" opacity={shouldShow ? 1 : 0} transparent  ></animated.meshPhongMaterial>
                        </Box>
                    </animated.group>
                </Suspense>)
        }

        return codonsResult
    }

    useEffect(() => {

        const fIndex = findFirstIndex(mBaseArray, toHideBase);
        const lIndex = findLastIndex(mBaseArray, toHideBase);

        setFirstIndex(fIndex);
        setLastIndex(lIndex);
    }, [toHideBase, mBaseArray])

    mBaseArray.forEach((letter, index) => {
        const isActive = toSplit.includes(index)
        // const needSplit = isActive && false

        var pointToSplit = 0.5 * (nonSpinRange[0] + nonSpinRange[1]);

        var splitValue = 0;
        if (nonSpinRange[0] !== nonSpinRange[1]) {

            splitValue = Math.cos(Math.PI * 0.5 * Math.min(1, Math.abs((index - pointToSplit) / ((nonSpinRange[0] - nonSpinRange[1]) / 2))))
        }

        const isInactive = inactiveBase.includes(index);
        const shouldHide = toHideBase.includes(index);
        const shouldHideTop = toHideTop.includes(index);

        const shouldHideBottom = toHideBottom.includes(index);

        const topFirstLB = ((topFirstLabel !== undefined) ? topFirstLabel : (isRNA ? "RNA 5'" : "5'"));
        const topEndLB = ((topEndLabel !== undefined) ? topEndLabel : (isRNA ? "3'" : "3'"));
        const bottomFirstLB = ((bottomFirstLabel !== undefined) ? bottomFirstLabel : (isRNA ? "3'" : "3'"));
        const bottomEndLB = ((bottomEndLabel !== undefined) ? bottomEndLabel : (isRNA ? "5' สายแม่แบบ" : "5' สายแม่แบบ"));

        const top = { isCodon: isCodon, type: getCounterBaseType(letter, isRNA), opacity: shouldHideTop ? 0 : 1, split: splitValue, visible: topVisible, firstLabel: topFirstLB, endLabel: topEndLB }
        const bottom = { type: letter, opacity: shouldHideBottom ? 0 : 1, split: splitValue, visible: bottomVisible, firstLabel: bottomFirstLB, endLabel: bottomEndLB }

        const isFirst = (index === firstIndex)
        const isLast = (index === lastIndex)


        console.log("isInactive " + index + isInactive)

        result.push(<DNAPair index={index} renderOrder={10 + index} showLabel={(!(intronGroups && intronGroups.length)) && showLabel} opacity={shouldHide ? 0 : (isInactive ? 0.1 : 1)} key={`dnapair_${index}`} isFirst={isFirst} isLast={isLast} label="g" position={[lastPaiPosX, 0, 0]} scale={!shouldHide ? 1 : 0} rotation={[currentSpin, 0, 0]} top={top} bottom={bottom} active={selectedPairs.includes(index)} onClick={(position) => {
            if (!isInactive)
                onBaseClicked(index)
        }}></DNAPair>)

        if (!shouldHide) {
            lastPaiPosX += 0.12;
        }

        if (index < nonSpinRange[0] || index > nonSpinRange[1]) {
            currentSpin = currentSpin + rotationStep * Math.PI / 180;
        }
        else {
            groupSpin = -currentSpin
        }

    })

    if (isCodon) {
        result.push(renderCodonProps(mArray, toHideTop))
    }

    var isIntron = false;
    var lastPosX = -0.12 / 2;

    intronGroups.forEach((iSize, index) => {

        const intronSize = iSize * 0.12
        const shouldHideGroup = toHideGroup.includes(index);

        result.push(
            <ExonIntronBox index={index} size={iSize} isIntron={isIntron} opacity={shouldHideGroup ? 0 : 1} position={[lastPosX + intronSize / 2, 0, 0]} onClick={(isIntron, boxIndex) => {
                var newSelected = [...selectedExonIntronIndex];

                if (newSelected.includes(boxIndex)) {
                    newSelected = newSelected.filter((a) => {
                        return a !== boxIndex;
                    })
                }
                else {
                    newSelected.push(boxIndex)
                }
                setSelectedExonIntronIndex(newSelected);
                onGroupClicked(boxIndex, isIntron);
            }} selectedIndex={selectedExonIntronIndex}></ExonIntronBox>
        )

        console.log("i size " + intronSize)
        console.log("i posX " + lastPosX)
        isIntron = !isIntron

        if (!shouldHideGroup) {
            lastPosX = lastPosX + intronSize
        }

    })

    if (groupRotation !== groupSpin) {
        console.log("groupSpin " + groupSpin)
        setGroupRotation(groupSpin)
    }
    return <animated.group  {...groupProps} position={[-0.12 * (mArray.length - toHideBase.length) / 2, 0, 0]} dispose={true} >
        <Suspense fallback={null}>
            {result}
        </Suspense>
    </animated.group>;
}

useGLTF.preload('/3D/dna_a_model.glb')
useGLTF.preload('/3D/dna_c_model.glb')
useGLTF.preload('/3D/dna_g_model.glb')
useGLTF.preload('/3D/dna_t_model.glb')

// const DNAString = (props) => {
//     const
//     return
// }