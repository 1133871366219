/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF, Merged } from '@react-three/drei'
import { DNAGameString } from './DNAGameModels'

export default function DNAString({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/3D/dna_string.glb')
  return (
    // <group ref={group} {...props} dispose={null}>
    //   <group rotation={[-2.53, 0.01, 0]}>
    //     <mesh geometry={nodes.Cube001.geometry} material={materials.dna_a_mat} />
    //     <mesh geometry={nodes.Cube001_1.geometry} material={materials.dna_t_mat} />
    //     <mesh geometry={nodes.Cube001_2.geometry} material={materials.dna_g_mat} />
    //     <mesh geometry={nodes.Cube001_3.geometry} material={materials.dna_c_mat} />
    //   </group>
    // </group>

    <DNAGameString scale={[5, 5, 5]} position={[-1, 0, 0]} showLabel={false} topFirstLabel="" topEndLabel="" bottomFirstLabel="" rotationStep={22} bottomEndLabel="" mArray={["a", "t", "c", "g", "a", "c", "g", "a", "t", "c", "g"]}></DNAGameString>
  )
  // return (
  //   <Merged meshes={nodes} materials={materials}>
  //     {({ Cube001, Cube001_1, Cube001_2, Cube001_3 }, { dna_a_mat, dna_t_mat, dna_g_mat, dna_c_mat }) => (

  //       <group ref={group} {...props} dispose={null}>
  //         <group rotation={[-2.53, 0.01, 0]}>
  //           <mesh geometry={Cube001.geometry} material={dna_a_mat} />
  //           <mesh geometry={Cube001_1.geometry} material={dna_t_mat} />
  //           <mesh geometry={Cube001_2.geometry} material={dna_g_mat} />
  //           <mesh geometry={Cube001_3.geometry} material={dna_c_mat} />
  //         </group>
  //       </group>
  //     )}
  //   </Merged>
  // )
}

useGLTF.preload('/3D/dna_string.glb')
