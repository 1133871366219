import './App.css';
import { useEffect, useState } from 'react';
import create from 'zustand'
import 'antd/dist/antd.css';
import { SERVER_URL } from './Config';
import touchSound from "./resources/sounds/touch.mp3"
import winSound from "./resources/sounds/success.mp3"
import loseSound from "./resources/sounds/fail.mp3"
import victorySound from "./resources/sounds/win1.mp3"
import completeSound from "./resources/sounds/win4.wav"
import gameOverSound from "./resources/sounds/win3.wav"
import menuSound from "./resources/sounds/family-holiday-119950.mp3"
import gameplaySound from "./resources/sounds/playing-in-color-120336.mp3"
import tutorialSound from "./resources/sounds/funny-111785.mp3"
import clapSound from "./resources/sounds/clap.mp3"
import wooshSound from "./resources/sounds/woosh.mp3"
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from './context/AuthContext';
import AuthUtil from './utils/auth';

const touchnSnd = new Audio(touchSound)
const losenSnd = new Audio(loseSound)
const winSnd = new Audio(winSound)
const victorySnd = new Audio(victorySound)
const completeSnd = new Audio(completeSound)
const gameOverSnd = new Audio(gameOverSound)
const menuSnd = new Audio(menuSound)
const gameplaySnd = new Audio(gameplaySound)
const tutorialSnd = new Audio(tutorialSound)
const clapSnd = new Audio(clapSound)
const wooshSnd = new Audio(wooshSound)

export const IS_DEV = false;
export const IS_FREE_PLAY = true;

let bgsnd;

const useStore = create((set, get) => ({
  pretestData: [],
  stages: [],
  pretestAnswers: [],
  pretestResult: null,
  updatePretestResult: (result) => {
    set(state => ({ pretestResult: result }))
  },
  backToPreviousPretest: () => {

    const currentPretestIndex = get().currentPretestIndex;
    if (currentPretestIndex > 0) {
      const newAnswers = [...get().pretestAnswers]
      newAnswers.pop();
      set(state => ({ pretestAnswers: [...newAnswers] }))
      set(state => ({ currentPretestIndex: state.currentPretestIndex - 1 }))
    }
    else {

      set(state => ({ pretestAnswers: [] }))
      set(state => ({ currentPretestIndex: 0 }))
    }
  },
  answerPretest: (index, questionID, answerChoiceID, result) => {
    console.log("answerPretest")
    set(state => ({ pretestAnswers: [...state.pretestAnswers, { index, questionID, answerChoiceID, result }] }))
    set(state => ({ currentPretestIndex: state.currentPretestIndex + 1 }))
  },
  resetPretest: () => {
    set(state => ({ pretestAnswers: [] }))
    set(state => ({ currentPretestIndex: 0 }))
  },
  currentPretestIndex: 0,
  playSound: (soundName) => {
    let snd;

    switch (soundName) {
      case "touch":
        snd = touchnSnd;
        break;
      case "win":
        snd = winSnd;
        break;
      case "victory":
        snd = victorySnd;
        break;
      case "complete":
        snd = completeSnd;
        break;
      case "gameover":
        snd = gameOverSnd;
        break;
      case "clap":
        snd = clapSnd;
        break;
      case "lose":
        snd = losenSnd;
        break;
      case "woosh":
        snd = wooshSnd;
        break;
    }
    if (snd) {
      snd.currentTime = 0
      snd.volume = 1
      snd.play()
    }
  },
  playBGSound: (soundName) => {

    console.log("playBGSound " + soundName)
    let newBGSND;
    switch (soundName) {
      case "menu":
        newBGSND = menuSnd;
        break;
      case "gameplay":
        newBGSND = gameplaySnd;
        break;
      case "tutorial":
        newBGSND = tutorialSnd;
        break;
    }

    if (!newBGSND)
      return;

    var needReset = false;

    if (bgsnd) {
      if (bgsnd !== newBGSND) {
        bgsnd.pause();
        bgsnd = newBGSND;
        needReset = true;
      }
    }
    else {
      needReset = true;
      bgsnd = newBGSND;
    }

    bgsnd = newBGSND;
    bgsnd.loop = true;
    if (bgsnd) {
      if (needReset)
        bgsnd.currentTime = 0
      bgsnd.volume = 1
      bgsnd.play()
    }
  },

  stopBGSound: () => {
    if (bgsnd) {
      bgsnd.pause();
      bgsnd = null;
    }
  },

  nextPretest: () => set(state => ({ currentPretestIndex: state.currentPretestIndex + 1 })),
  updatePretestData: (newPretestData) => set(state => ({ pretestData: newPretestData })),
  updateStages: (stagesData) => set(state => ({ stages: stagesData })),
}))


export const loadPretestData = async () => {
  const res = await fetch(`${SERVER_URL}/pretest-questions`);
  // const res = await fetch("http://localhost:1337/pretest-questions");
  const resObj = await res.json();
  const sortedResObj = resObj.sort((a, b) => a.ordering - b.ordering)
  // console.log("resObj " + JSON.stringify(sortedResObj));
  return sortedResObj
}


export const loadStages = async () => {
  const res = await fetch(`${SERVER_URL}/stages`);
  const resObj = await res.json();
  const sortedResObj = resObj.sort((a, b) => a.ordering - b.ordering)
  return sortedResObj
}


function App() {
  const updatePretestData = useStore(state => state.updatePretestData);
  const updateStages = useStore(state => state.updateStages);

  const { user, logout, loadUser } = useAuth();

  let navigate = useNavigate();
  const location = useLocation();
  console.log(location.pathname);

  useEffect(() => {

    if (IS_DEV || (location.pathname === "/export")) {
      return;
    }
    if (!user) {
      navigate("../login", { replace: true });
    }

  }, [user])


  useEffect(async () => {


    loadUser();
    AuthUtil.needLogoutCallback = logout;
    const data = await loadPretestData();
    const stages = await loadStages();
    updatePretestData(data)
    updateStages(stages)


    if (IS_DEV || (location.pathname === "/export")) {
      return;
    }
    if (!user) {
      navigate("../login", { replace: true });
    }
    else {
      navigate("../menu", { replace: true });
    }

    // navigate("../menu", { replace: true });
  }, [])

  return (
    // <div style={{ width: "100%", height: "100%", margin: 0, padding: 0, background: "url('./images/dna_background.png')", fontFamily: "Kanit", overflowY: "auto", overflowX: "hidden" }}>
    <div style={{ width: "100%", height: "100%", margin: 0, padding: 0, fontFamily: "Kanit", overflowY: "auto", overflowX: "hidden" }}>
      <Outlet />
    </div>
  );
}

export const useAppStore = useStore;

export default App;
