import React, { useEffect, useState } from 'react';
import { Menu, } from 'antd'
import {
    CheckOutlined
} from '@ant-design/icons';
import { Link } from "react-router-dom";
import "../../interface.scss";
import { IS_DEV, useAppStore, IS_FREE_PLAY, loadPretestData } from "../../App";
import { useAuth } from '../../context/AuthContext';
import { authGetRequest } from '../../utils/server';
import { SERVER_URL } from '../../Config';

function MainMenu(props) {

    const { logout, user, getToken } = useAuth();
    const stages = useAppStore(state => state.stages);
    const playBGSound = useAppStore(state => state.playBGSound)
    const pretestResult = useAppStore(state => state.pretestResult);
    const updatePretestResult = useAppStore(state => state.updatePretestResult);

    const [title, setTitle] = useState("")
    const [subtitle, setSubTitle] = useState("")
    const [playedStages, setPlayedStages] = useState([]);

    useEffect(() => {

        console.log("user " + JSON.stringify(user))
        if (user) {
            if (pretestResult) {
                setTitle(`ผู้เล่น ${user.user.username}`)
                setSubTitle(`เริ่มเล่นเกมกันเถอะ`)
            }
            else {
                setTitle(`สวัสดี ${user.user.username}`)
                setSubTitle(`ยินดีต้อนรับสู่`)
            }
        }

    }, [pretestResult, user])

    useEffect(() => {

        playBGSound("menu");
        loadPlayedStageResults();
        loadPretestResult();
    }, [])

    const loadPlayedStageResults = async () => {
        if (user) {

            const rawresult = await fetch(`${SERVER_URL}/stage-results?user=${user.user.id}`)
            const result = await rawresult.json();
            console.log(JSON.stringify(result))
            const rawPlayedStages = result.map(r => `stage${r.stage.id}`)

            const unitquPlayedStages = rawPlayedStages.filter((value, index, self) => (rawPlayedStages.indexOf(value) === index))
            console.log(unitquPlayedStages)
            setPlayedStages(unitquPlayedStages);
            // setPlayedStages(["stage1"]);
        }

    }

    const loadPretestResult = async () => {
        if (user) {

            const rawresult = await fetch(`${SERVER_URL}/pretest-answers?user=${user.user.id}&_sort=created_at:DESC&_limit=1`)
            const result = await rawresult.json();
            console.log(JSON.stringify(result))

            if (result.length)
                updatePretestResult(result[0])
        }

    }

    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%", background: "url('./images/dna_background.png')", fontFamily: "Kanit", fontSize: "1.3em", lineHeight: "1.2em", padding: "30px" }}>


            <span style={{ fontSize: "32px", fontWeight: 600, marginBottom: "20px", color: "rgba(0,0,0,0.5)" }}>{title}</span>
            <span style={{ fontSize: "24px", fontWeight: 400, marginBottom: "30px", color: "rgba(0,0,0,0.5)" }}>{subtitle}</span>
            <span style={{ fontSize: "40px", fontWeight: 600, marginBottom: "30px" }}>DNA MASTER</span>
            {/* <img src="./images/banner.jpg" style={{ width: "80%", maxWidth: "500px", objectFit: "contain", borderRadius: "20px", border: "solid 5px lightgray", margin: "40px" }}></img> */}
            <div style={{ maxWidth: "600px" }}>
                <Link key="pretest_btn" to="/pretest/false" className="btn orange">เริ่มทำแบบทดสอบความรู้</Link>

                {/* {((pretestResult && pretestResult.stages_to_play) || (IS_DEV || IS_FREE_PLAY)) ? stages.sort((a, b) => (a.ordering - b.ordering)).map((stage, index) => { */}
                {(pretestResult && pretestResult.stages_to_play) ? stages.sort((a, b) => (a.ordering - b.ordering)).map((stage, index) => {
                    const stageKey = `stage${index + 1}`;

                    const activeColor = playedStages.includes(stageKey) ? "lightblue" : "blue"
                    // const stagesToPlay = [...(pretestResult ? pretestResult.stages_to_play : []), "stage6"]
                    const stagesToPlay = [...(pretestResult ? pretestResult.stages_to_play : []), "stage6"]
                    return <div style={{ display: "flex", alignItems: "center" }}><Link to={`/stage${stage.id}`} className={(stagesToPlay.includes(stageKey) || (IS_DEV || IS_FREE_PLAY)) ? `btn ${activeColor}` : `btn ${activeColor} disabled`}>{playedStages.includes(stageKey) ? <CheckOutlined style={{ marginRight: "5px", marginLeft: "-5px" }} /> : null}{stage.name}</Link> {stage.topic}</div>
                }) : null}
            </div>
            <a className='btn cancel' onClick={() => { logout() }}>ลงชื่อออก</a>
        </div >
    );
    // return (
    //     <div>
    //         <Menu theme="light">
    //             <Menu.Item><Link to="/pretest"><div className="btn orange">Pretest</div></Link></Menu.Item>
    //             <Menu.Item><Link to="/stage1"><div className="btn blue">ด่านที 1</div></Link></Menu.Item>
    //             <Menu.Item disabled><Link to="/stage2"><div className="btn blue">ด่านที 2</div></Link></Menu.Item>
    //             <Menu.Item disabled><Link to="/stage3"><div className="btn blue">ด่านที 3</div></Link></Menu.Item>
    //             <Menu.Item disabled><Link to="/stage4"><div className="btn blue">ด่านที 4</div></Link></Menu.Item>
    //             <Menu.Item disabled><Link to="/stage5"><div className="btn blue">ด่านที 5</div></Link></Menu.Item>
    //         </Menu>;
    //     </div>
    // );
}

export default MainMenu;