import React, { useState, Suspense, useRef, useEffect, useImperativeHandle } from 'react'
import * as THREE from "three"
import create from "zustand"
import { DefaultXRControllers, ARCanvas, useXREvent, Interactive, canva } from '@react-three/xr'
import { Text, useGLTF, Html, Billboard, OrbitControls, Environment, Float, Loader, Preload, ContactShadows, Image } from '@react-three/drei'
import { useFrame, useLoader, Canvas } from "@react-three/fiber"
import { useAppStore } from '../../App'
import { useSpring, animated } from '@react-spring/three'
import { PlaneBufferGeometry } from 'three'
import { Mesh } from 'three'
import { Button } from '../models/Models';
import { DNAGameString } from '../models/DNAGameModels'



function DNANode({ ...props }) {
  const [hover, setHover] = useState(false)
  const group = useRef()
  // const [focus, setFocus] = useState(false);
  const { nodes, materials } = useGLTF('3D/dna_game_models.glb');
  const [thisGeom, setThisGeom] = useState();
  const [thisMat, setThisMat] = useState();
  const [thisFocusMat, setThisFocusMat] = useState();

  const [zoomIngameRef, setZoomIngameRef] = useState(false)
  const ingameRefProps = useSpring({
    scale: zoomIngameRef ? [2, 2, 2] : [1, 1, 1]
  })

  //a, t, g, c
  const { focus = false, onClick = () => { }, type = "a", active = true } = props;

  const [isFocus, setIsFocus] = useState(false);

  const inactiveMat = new THREE.MeshPhongMaterial({ color: 0x99ff99 })

  useEffect(() => {
    setIsFocus(focus)
  }, [focus])

  let nodeType = type === "t" ? "t" : type;

  useEffect(() => {

    if (thisGeom) {
      setThisGeom(null)
    }
    if (thisMat) {
      setThisMat(null)
    }
    if (thisFocusMat) {
      setThisFocusMat(null)
    }
    if (nodes && nodes[`dna_${nodeType}`] && nodes[`dna_${nodeType}`].geometry && materials && materials[`dna_${nodeType}_mat`]) {
      const _thisGeom = nodes[`dna_${nodeType}`].geometry.clone();
      const _thisMat = materials[`dna_${nodeType}_mat`];
      const _thisFocusMat = _thisMat.clone();
      _thisFocusMat.opacity = 0.5;
      setThisGeom(_thisGeom);
      setThisMat(_thisMat);
      setThisFocusMat(_thisFocusMat);
    }

  }, [nodes, materials])


  if (!thisGeom || !thisMat)
    return null;

  return (
    // <Interactive onSelect={() => setHover(true)} onBlur={() => setHover(false)} onSelect={()=>{
    //     onClick({nodeType, index});
    // }}>
    <group ref={group} {...props} dispose={null}>

      {/* <Box color={isFocus ? "green" : "red"} size={[0.1, 0.6, 0.1]} >
        </Box> */}
      <mesh geometry={thisGeom} material={active ? (isFocus ? thisFocusMat : thisMat) : inactiveMat} />

      <Text position={[0, -0.08, 0.015]} fontSize={0.06} color="#000" anchorX="center" anchorY="middle">
        {type}
      </Text>
      <Text position={[0, -0.08, -0.015]} rotation={[Math.PI, 0, 0]} fontSize={0.06} color="#000" anchorX="center" anchorY="middle">
        {type}
      </Text>
      {/* <mesh geometry={nodes.dna_t.geometry} material={materials.dna_t_mat} />
          <mesh geometry={nodes.dna_g.geometry} material={materials.dna_g_mat} />
          <mesh geometry={nodes.dna_c.geometry} material={materials.dna_c_mat} /> */}
    </group>
    // </Interactive>
  )
}


const getConplementaryDNANode = (startingLetter) => {
  switch (startingLetter) {
    case "a":
      return "t";
      break;
    case "t":
    case "u":
      return "a";
      break;
    case "c":
      return "g";
      break;
    case "g":
      return "c";
      break;
  }
}

function DNAPair({ ...props }) {
  const { type = "a", index = 0, focus = false, onClick = () => { }, active = true } = props;
  const group = useRef()
  const complementaryType = getConplementaryDNANode(type);
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    setIsFocus(focus)
  }, [focus])

  return (
    <Interactive key={`dnaPairInteractive${type}${index}`} onSelect={() => {
      if (active)
        onClick(index);
    }}>
      <group key={`dnaPair${type}${index}`} ref={group} {...props} scale={isFocus ? [1.2, 1.2, 1.2] : [0.9, 0.9, 0.9]} opacity={active ? 1 : 0.5} onClick={(event) => {
        event.stopPropagation();
        if (active) {
          console.log("click on click")
          onClick(index)
        }
      }}>
        <DNANode focus={isFocus} key={`dna_${type}_${index}`} type={type} position={[0, -0.01, 0]} rotation={[0, 0, 0]} active={active} />
        <DNANode focus={isFocus} key={`dnacom_${complementaryType}_${index}`} type={complementaryType} position={[0, 0.01, 0]} rotation={[Math.PI, 0, 0]} active={active} />
      </group>
    </Interactive>
  )
}


const DNA = React.forwardRef(({ ...props }, ref) => {
  const { sequence, onCorrect = () => { }, onWrong = () => { }, request } = props
  const group = useRef()
  const startFiveRef = useRef()
  const startThreeRef = useRef()
  const endFiveRef = useRef()
  const endThreeRef = useRef()
  const rotationStep = 20;
  // const rotationStep = 0;
  const gap = 0.15;
  const startPos = -gap * sequence.length / 2;

  const orbitref = useRef()
  const dnaPairRef = useRef()
  const [selected, setSelected] = useState([])
  const [inactives, setInactives] = useState([])
  const playSound = useAppStore(state => state.playSound)

  useImperativeHandle(ref, () => ({
    resetSelections: () => {
      console.log("resetSelections")
      setSelected([]);
      setInactives([]);
    }
  }));

  useEffect(() => {
    return () => {
      if (group.current) {
        group.current.dispose();
      }
    }

  }, [])


  return (
    <animated.group ref={group} {...props}>
      <Billboard
        ref={startFiveRef}
        position={[startPos + -1 * gap, 0.15, 0.06]}
        follow={false}
        lockX={false}
        lockY={false}
        lockZ={false} // Lock the rotation on the z axis (default=false)
      >
        <Text font="/fonts/ttf/fc_condensed.ttf" fontSize={0.12} color="black">5'</Text>
      </Billboard>
      <Billboard
        ref={startThreeRef}
        position={[startPos + -1 * gap, -0.15, 0.06]}
        follow={false}
        lockX={false}
        lockY={false}
        lockZ={false} // Lock the rotation on the z axis (default=false)
      >
        <Text font="/fonts/ttf/fc_condensed.ttf" fontSize={0.12} color="black">3'</Text>
      </Billboard>
      <Billboard
        ref={endFiveRef}
        position={[startPos + sequence.length * gap + 0.2, -0.15, 0.06]}
        follow={false}
        lockX={false}
        lockY={false}
        lockZ={false} // Lock the rotation on the z axis (default=false)
      >
        <Text font="/fonts/ttf/fc_condensed.ttf" fontSize={0.12} color="black">5' สายแม่แบบ</Text>
      </Billboard>
      <Billboard
        ref={endThreeRef}
        position={[startPos + sequence.length * gap, 0.15, 0.06]}
        follow={false}
        lockX={false}
        lockY={false}
        lockZ={false} // Lock the rotation on the z axis (default=false)
      >
        <Text font="/fonts/ttf/fc_condensed.ttf" fontSize={0.12} color="black">3'</Text>
      </Billboard>
      {sequence.map((letter, index) => {
        const isSelected = selected.includes(index)
        return (
          <DNAPair key={`dnapairsequence_${index}`} focus={isSelected} type={letter} index={index} position={[startPos + index * gap, 0, 0.06]} active={!inactives.includes(index)} onClick={(localindex) => {

            let newSelected = [];

            if (selected.length >= 5)
              return;

            if (selected.includes(localindex)) {
              newSelected = [...selected].filter(oldID => oldID !== localindex)
            }
            else {
              newSelected = [...selected]
              newSelected.push(localindex);
            }

            console.log("onClick")
            playSound("touch")

            async function checkResult() {

              console.log("checkResult")
              await setTimeout(() => {
                const orderedSelected = newSelected.sort((a, b) => (a - b));

                if (!((orderedSelected[2] - orderedSelected[1]) == 1 && (orderedSelected[1] - orderedSelected[0]) == 1)) {

                  playSound("lose");
                  setSelected([])
                  onWrong();
                }
                else {

                  const joinedSelected = orderedSelected.map((sequenceIndex) => sequence[sequenceIndex]).join("");

                  const startCodons = ["aatcg", "gccat", "ccaat", "tcagt", "cgcgt"];
                  const stopCodons = ["agtca", "tactg", "ggcat", "ctact"];
                  if (startCodons.includes(joinedSelected)) {
                    playSound("win");
                    setSelected([])
                    setInactives([...inactives, ...orderedSelected])
                    onCorrect();
                  }
                  else if (stopCodons.includes(joinedSelected)) {
                    playSound("win");
                    setSelected([])
                    setInactives([...inactives, ...orderedSelected])
                    onCorrect();
                  }
                  else {
                    // playSound("lose");
                    setSelected([])
                    onWrong();
                  }
                }

              }, 500);

            }

            if (newSelected.length >= 5) {
              setSelected(newSelected)
              checkResult()
            }
            else {
              setSelected(newSelected)
            }
          }} rotation={[Math.PI * index * rotationStep / 180, 0, 0]} />

        )
      })}
      <OrbitControls ref={orbitref} />
    </animated.group>
  )
})


export function Stage1(props) {
  const textref = useRef();
  const dnaref = useRef();
  const playSound = useAppStore(state => state.playSound)

  const { gameData = null, onAnswered } = props;
  const [qSequence, setQSequence] = useState(null)
  const [clearDNA, setClearDNA] = useState(false)
  const [toHideTop, setToHideTop] = useState([])
  const [toHideBottom, setToHideBottom] = useState([])
  const [toHidePair, setToHidePair] = useState([])
  const [toHideGroup, setToHideGroup] = useState([])


  const [nonSpinRange, setNonSpinRange] = useState([0, 0])
  const [correctCount, setCorrectCount] = useState(0)
  const [selected, setSelected] = useState([])
  const [twist, settwist] = useState(true)
  const [inactives, setInactives] = useState([])

  const dnaProps = useSpring({ scale: clearDNA ? [0, 0, 0] : [1, 1, 1], position: [0, -0.1, -0.5] })
  var questionsSet = [
    ["a", "a", "t", "c", "g", "c", "t", "a", "t", "t", "c", "a", "g", "t", "c", "a", "t", "g", "a"],
    ["g", "a", "a", "t", "c", "g", "t", "c", "g", "a", "c", "a", "a", "t", "a", "g", "t", "c", "a"],
    ["t", "g", "c", "a", "a", "t", "c", "g", "a", "t", "g", "a", "g", "t", "c", "a", "t", "c", "a"],
    // ["a", "t", "g", "g", "a", "c", "t", "a", "g", "t", "c", "a", "g", "t", "c", "a", "c", "g", "a"],
    // ["c", "t", "t", "g", "a", "c", "t", "a", "g", "t", "c", "a", "g", "t", "c", "a", "c", "g", "a"],
    // ["g", "t", "c", "g", "a", "c", "t", "a", "g", "t", "c", "a", "g", "t", "c", "a", "c", "g", "a"]
  ]

  // const startCodons = ["aatcg"];
  // const stopCodons = ["agtca"];
  const getNewQuestion = async () => {
    const newQ = questionsSet.sort(() => (Math.random() > 0.5 ? -1 : 1)).pop();
    setQSequence(newQ)

    setClearDNA(true)
    // if (dnaref.current) {
    //   dnaref.current.resetSel
    //   ections();
    // }

    setInactives([]);
    setTimeout(() => {
      setClearDNA(false)
    }, 1000)
    console.log("getNewQuestion correctCount " + correctCount)
    setCorrectCount(0)
  }

  useEffect(() => {
    getNewQuestion()

    console.log("useeffect correctCount " + correctCount)
    setCorrectCount(0)
  }, [])


  //found promoter and terminator but game not requestion
  useEffect(() => {
    if (correctCount >= 2) {

      onAnswered(1, true, getNewQuestion, true)
    }
  }, [correctCount])

  if (!qSequence) {
    return null;
  }

  const onCorrect = () => {
    setCorrectCount(correctCount + 1)
    console.log("correctCount " + correctCount)

  }


  const onWrong = () => {
    onAnswered(1, false, getNewQuestion, false)
  }

  const toggleTwist = () => {

    settwist(!twist);
    playSound("woosh")
  }

  const onBaseSelected = (localindex) => {
    // if (selectedPairs.includes(index)) {
    //   setSelectedPairs(selectedPairs.filter((p) => p !== index));
    // }
    // else
    //   setSelectedPairs([...selectedPairs, index])
    let newSelected = [];

    if (selected.length >= 5)
      return;

    if (selected.includes(localindex)) {
      newSelected = [...selected].filter(oldID => oldID !== localindex)
    }
    else {
      newSelected = [...selected]
      newSelected.push(localindex);
    }

    console.log("onClick")
    playSound("touch")

    async function checkResult() {



      console.log("checkResult")
      await setTimeout(() => {
        const orderedSelected = newSelected.sort((a, b) => (a - b));

        if (!((orderedSelected[2] - orderedSelected[1]) == 1 && (orderedSelected[1] - orderedSelected[0]) == 1)) {

          playSound("lose");
          setSelected([])
          onWrong();
        }
        else {

          const joinedSelected = orderedSelected.map((sequenceIndex) => qSequence[sequenceIndex]).join("");

          // const startCodons = ["aatcg", "gccat", "ccaat", "tcagt", "cgcgt"];
          // const stopCodons = ["agtca", "tactg", "ggcat", "ctact"];
          const startCodons = ["aatcg"];
          const stopCodons = ["agtca"];


          if (startCodons.includes(joinedSelected)) {
            playSound("win");
            setSelected([])
            setInactives([...inactives, ...orderedSelected])
            onCorrect();
          }
          else if (stopCodons.includes(joinedSelected)) {
            playSound("win");
            setSelected([])
            setInactives([...inactives, ...orderedSelected])
            onCorrect();
          }
          else {
            // playSound("lose");
            setSelected([])
            onWrong();
          }
        }

      }, 500);

    }

    if (newSelected.length >= 5) {
      setSelected(newSelected)
      checkResult()
    }
    else {
      setSelected(newSelected)
    }
  }
  return (
    <>
      {/* <DNA ref={dnaref}  {...dnaProps} sequence={qSequence} onCorrect={onCorrect} onWrong={onWrong} /> */}
      <DNAGameString {...dnaProps} mArray={qSequence} rotationStep={twist ? 22 : 0} nonSpinRange={nonSpinRange} toHideTop={toHideTop} selectedPairs={selected} toHideBottom={toHideBottom} toHidePair={toHidePair} inactivePair={inactives} toHideGRoup={toHideGroup} onBaseClicked={onBaseSelected}></DNAGameString>
      <Interactive onSelect={() => {

        setSelected([])
      }}>
        <Button title={"ยกเลิกการเลือก"} onClick={() => {
          setSelected([])


        }} bgColor="orange" position={[0, -0.5, 0.3]} />
      </Interactive>
      <Interactive onSelect={() => {
        toggleTwist();
      }}>
        <Button title={twist ? "คลายเกลียว" : "หมุนเกลียว"} onClick={() => {
          toggleTwist();


        }} bgColor={(twist ? "#bbfdb5" : "#ffc9de")} position={[0.5, -0.5, 0.3]} />
      </Interactive>
      {/* <group position={[0, 0, 0]}>
        <mesh>
          <planeBufferGeometry attach="geometry" args={[2, 1]} ></planeBufferGeometry>
          <meshStandardMaterial attach="material" color={"grey"} side={true} />
        </mesh>
        <Text fontSize={0.12} color="black" position={[0, 0, 0.1]}>GAME OVER</Text>
      </group> */}
    </>
  )
}
