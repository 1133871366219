import React, { useEffect, useState } from 'react';
import { SERVER_URL } from '../Config';
import { useStageStore } from './StageTemplate';
import "../interface.scss";
import { Modal, Carousel, Comment, Avatar, Button, Divider, Row, Col } from 'antd';
import { authGetRequest } from '../utils/server';
import { useAuth } from '../context/AuthContext';
import moment from 'moment';


function WrittenQuestionPage() {
    const [answer, setAnswer] = useState("");

    const currentStage = useStageStore(state => state.currentStage)
    const nextStep = useStageStore(state => state.nextStep)
    const prevStep = useStageStore(state => state.prevStep)

    const answerWritten = useStageStore(state => state.answerWritten)
    const resetWrittenAnswer = useStageStore(state => state.resetWrittenAnswer)
    const [prevAnswers, setPrevAnswers] = useState([]);
    const { getToken } = useAuth();
    const ColorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];

    useEffect(async () => {
        resetWrittenAnswer();
        const prevAnsData = await retrievePrevAnswers();
        setPrevAnswers(prevAnsData)
    }, [])

    const contentStyle = {
        color: '#444',
        lineHeight: '20px',
        fontSize: "18px",
        textAlign: 'center',
    };
    const authorStyle = {
        color: '#666',
        lineHeight: '20px',
        fontSize: "16px",
        textAlign: 'center',
    };

    const retrievePrevAnswers = async () => {
        // http://159.223.34.186:1337/stage-results?stage=1&&_limit=5
        // const result = await authGetRequest(`stage-results?stage=${currentStage.id}&&_limit=5`)
        const token = getToken();
        if (token) {

            const rawresult = await fetch(`${SERVER_URL}/stage-results?stage=${currentStage.id}&_sort=id:DESC&_limit=5`)
            const result = await rawresult.json();
            console.log(JSON.stringify(result))
            const writtenResults = result.map(r => {
                return { answer: r.written_answer, user: r.user.username, date: r.created_at }
            })
            // console.log(JSON.stringify(writtenResults)) 

            return writtenResults
        }

        return []
    }

    if (!currentStage)
        return null

    const handleResultChanged = (event) => {
        setAnswer(event.target.value);
    }


    const handleSaveResult = (event) => {
        if (answer !== "") {
            console.log("currentStage.id " + currentStage.id)
            console.log("handleSaveResult " + answer)
            answerWritten(answer)
            setAnswer("")
            nextStep();
        }
    }
    return (
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", height: "100%" }}>
            {/* {currentStage.cover_image ? <img src={SERVER_URL + currentStage.cover_image.url} style={{ width: "85%", maxWidth: "640px", height: "auto", maxHeight: "480px", objectFit: "cover", margin: "20px", borderRadius: "20px" }}></img> : null} */}
            {/* <span className="dnaHeader" style={{ textAlign: "center" }}>{currentStage.name}</span> */}

            <img src={`${SERVER_URL}${currentStage.ingame_ref.url}`} style={{ border: "1px solid lightgray", borderRadius: "12px", padding: "12px", width: "650px", maxWidth: "85%", marginBottom: "20px", maxHeight: "350px", objectFit: "contain" }} />
            <span className="dnaQuestionHeader" style={{ textAlign: "center", marginBottom: "20px" }}>"{currentStage.question1}"</span>

            <Row justify="center">
                <Col xl={14} lg={14} md={12} sm={20} xs={20} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div>
                        <textarea value={answer} maxLength={280} onChange={handleResultChanged} id="w3review" name="w3review" rows="6" cols="50" style={{ marginBottom: "20px", width: "100%", padding: "8px" }}></textarea>

                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <a onClick={handleSaveResult} className={(answer === "") ? "btn orange disabled" : "btn orange"}>บันทึก</a>
                        </div>
                    </div>
                </Col>
                <Col xl={10} lg={10} md={12} sm={20} xs={20} style={{ padding: "0 20px" }}><Divider plain>คำตอบจากเพื่อน</Divider>
                    <Carousel autoplay style={{ maxWidth: "250px", marginBottom: "20px", maxHeight: "500px" }} dots={false}>
                        {
                            prevAnswers.map((ans, index) => {

                                const color = index < ColorList.length - 1 ? ColorList[index + 1] : ColorList[0]

                                // return <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", textAlign: "center" }}>
                                //     <Avatar style={{ backgroundColor: color, verticalAlign: 'middle', margin: "auto", display: "inline-block" }} size="large" gap={4}>
                                //         {ans.user}
                                //     </Avatar>
                                //     <h3 style={contentStyle} >{ans.answer}</h3>
                                // </div>
                                return <div style={{ backgroundColor: "white", display: "inline-block" }}><Comment
                                    //    actions={actions}
                                    author={<a>{ans.user}</a>}
                                    avatar={<Avatar style={{ backgroundColor: color, verticalAlign: 'middle', margin: "auto", display: "inline-block" }} size="large" gap={4}>
                                        {ans.user}
                                    </Avatar>}
                                    content={
                                        <p>
                                            {ans.answer}
                                        </p>
                                    }
                                    datetime={
                                        // <Tooltip title={ans.date}>
                                        <span>{moment(ans.date).startOf('hour').fromNow()}</span>
                                        // </Tooltip>
                                    }
                                />
                                </div>
                            })
                        }

                    </Carousel>
                </Col>
            </Row>



        </div>
    );
}

export default WrittenQuestionPage;