import { SERVER_URL } from "../Config";
import AuthUtil from "./auth";


/**
 * request PUT with authentication
 * 
 * @param {String} action 
 * @param {Object} jsonBody 
 * @returns 
 */
export const authPutRequest = async (action, id, jsonBody, token) => {


    if (!token) {
        return null;
    }
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(jsonBody)
    };

    const res = await fetch(`${SERVER_URL}/${action}/${id}`, requestOptions);
    const resData = await res.json();

    if (resData && "statusCode" in resData) {
        if (resData.statusCode === 401) {
            AuthUtil.needLogoutCallback();
        }
    }
    return resData;
}

/**
 * request POST with authentication
 * 
 * @param {String} action 
 * @param {Object} jsonBody 
 * @returns 
 */
export const authPostRequest = async (action, jsonBody, token) => {


    if (!token) {
        return null;
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(jsonBody)
    };

    const res = await fetch(`${SERVER_URL}/${action}/`, requestOptions);
    const resData = await res.json();

    if (resData && "statusCode" in resData) {
        if (resData.statusCode === 401) {
            AuthUtil.needLogoutCallback();
        }
    }
    else
        return resData;
}

/**
 * request GET with authentication
 * 
 * @param {String} action
 * @returns 
 */
export const authGetRequest = async (action, token) => {


    if (!token) {
        return null;
    }
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    const res = await fetch(`${SERVER_URL}/${action}/`, requestOptions);
    const resData = await res.json();

    if (resData && "statusCode" in resData) {
        if (resData.statusCode === 401) {
            AuthUtil.needLogoutCallback();
        }
    }
    return resData;
}

/**
 * request Delete with authentication
 * 
 * @param {String} action 
 * @param {Object} jsonBody 
 * @returns 
 */
export const authDeleteRequest = async (action, id, token) => {


    if (!token) {
        return null;
    }
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    };

    const res = await fetch(`${SERVER_URL}/${action}/${id}/`, requestOptions);
    const resData = await res.json();

    if (resData && "statusCode" in resData) {
        if (resData.statusCode === 401) {
            AuthUtil.needLogoutCallback();
        }
    }
    else
        return resData;
}