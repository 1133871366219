import * as THREE from 'three';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import helvFont from 'three/examples/fonts/helvetiker_bold.typeface.json';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { useEffect, useState } from 'react';
import { useSpring, animated } from '@react-spring/three'
import { Text, Billboard, Loader, Preload, Image } from '@react-three/drei'
import { DefaultXRControllers, ARCanvas, useXREvent, Interactive, canva } from '@react-three/xr'

export const DNANode = () => {

    var redMat = new THREE.MeshStandardMaterial({ color: 0xcc3300 })
    var greenMat = new THREE.MeshStandardMaterial({ color: 0xcc00b050 })
    var blackMat = new THREE.MeshStandardMaterial({ color: 0x222222 })

    const radius = 0.15;  // ui: radius
    const detail = 5;  // ui: detail
    const g = new THREE.IcosahedronBufferGeometry(radius, detail);
    const baseMesh = new THREE.Mesh(g, redMat);

    // loader.load('resource/helvetiker_regular.typeface.json', (font) => {
    const text = 'A';  // ui: text
    const letterGeometry = new TextGeometry(text, {
        font: helvFont,
        size: 0.14,  // ui: size
        height: 5,  // ui: height
    });


    const letterMesh = new THREE.Mesh(letterGeometry, blackMat);
    letterMesh.position.y = 0.25
    letterMesh.position.x = -0.065
    letterMesh.position.z = 0.02
    group.add(letterMesh)
    // })


    const shape = new THREE.Shape();
    const x = 0;
    const y = 0;
    shape.moveTo(x - (radius / 2), y);
    shape.lineTo(x - (radius / 2), y + 0.5)
    shape.lineTo(x + (radius / 2), y + 0.5)
    shape.lineTo(x + (radius / 2), y)
    // shape.bezierCurveTo(x + 2.5, y + 2.5, x + 2, y, x, y);

    const extrudeSettings = {
        steps: 2,  // ui: steps
        depth: 0.1,  // ui: depth
        bevelEnabled: false
        // bevelEnabled: true,  // ui: bevelEnabled
        // bevelThickness: 0.01,  // ui: bevelThickness
        // bevelSize: 0.01,  // ui: bevelSize
        // bevelSegments: 2,  // ui: bevelSegments
    };

    const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);

    const stemMesh = new THREE.Mesh(geometry, greenMat);
    stemMesh.position.z = -0.05

    const group = new THREE.Object3D();
    group.add(baseMesh)
    group.add(stemMesh)
    return group;
}



const findType = (object, type) => {
    object.children.forEach((child) => {
        if (child.type === type) {
            console.log(child);
        }
        findType(child, type);
    });
}
export const loadDNAStrand = async (scene) => {

    var blackMat = new THREE.MeshStandardMaterial({ color: 0x222222 })
    var totalNodes = 20;
    var gap = 0.1;
    var startX = -(gap * totalNodes) / 2

    const group = new THREE.Group();
    const loader = new GLTFLoader();
    const fontLoader = new FontLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderConfig({ type: 'js' });
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
    loader.setDRACOLoader(dracoLoader);

    fontLoader.load("fonts/helvetiker_bold.typeface.json", (font) => {
        loader.load("3D/dna_game_models.glb", function (gltf) {


            const dnaNodes = {};
            const dnaArray = gltf.scene.children.filter(mesh => {

                return (mesh.type === "Mesh")
            })

            dnaArray.forEach((mesh, index) => {

                const dnaUnitGroup = new THREE.Object3D();
                const materials = [
                    new THREE.MeshPhongMaterial({ color: 0x000000 }), // front
                ];

                const dnaUnitName = mesh.name.replace("dna_", "");

                const textGeo = new TextGeometry(dnaUnitName, {

                    font: font,
                    size: 0.05,
                    height: 0.05


                });
                const label = new THREE.Mesh(textGeo, [blackMat]);
                label.position.z = -0.02;
                label.position.y = -0.08;
                label.position.x = -0.02;
                dnaUnitGroup.add(mesh);
                dnaUnitGroup.add(label);


                console.log("mesh name " + mesh.name)
                // mesh.on('click', () => {
                //     console.log("dna clicked")
                // }, true)
                // dnaUnitGroup.onClick = () => {
                //     console.log("hi")
                // }
                // const joined = new THREE.BufferGeometryUtils.mergeBufferGeometries([mesh, label])
                dnaNodes[mesh.name] = dnaUnitGroup;
                dnaUnitGroup.name = mesh.name
                // dnaNodes[mesh.name] = label;
            })

            console.log(Object.keys(dnaNodes))

            const meshes = findType(gltf.scene, 'Mesh');

            // const startingStrand = ["a", "t", "c", "g", "a", "c", "a", "a", "g", "t", "c", "a", "g", "c", "a", "a", "t", "c", "g", "a"]
            const startingStrand = ["a", "t", "c", "g", "a", "c", "a", "a", "g", "t", "c", "a", "g", "c", "a", "a"]

            totalNodes = startingStrand.length;
            startX = -(gap * totalNodes) / 2
            const conmplementaryStrand = getConplementaryDNAStrand(startingStrand);
            for (var i = 0; i < startingStrand.length; i++) {
                var node = dnaNodes[`dna_${startingStrand[i]}`].clone();
                node.name = `dna_${startingStrand[i]}`;
                var complementaryNode = dnaNodes[`dna_${conmplementaryStrand[i]}`].clone();
                complementaryNode.name = `dna_${conmplementaryStrand[i]}`;
                node.position.x = startX + (i * gap);
                complementaryNode.position.x = startX + (i * gap);
                complementaryNode.rotation.z = Math.PI;

                const selectedScale = 1.5;
                if (i === 2) {
                    node.scale.x = selectedScale
                    node.scale.y = selectedScale
                    node.scale.z = selectedScale
                    complementaryNode.scale.x = selectedScale
                    complementaryNode.scale.y = selectedScale
                    complementaryNode.scale.z = selectedScale
                }
                node.rotation.x = i * Math.PI * 2 / 24;
                complementaryNode.rotation.x = (i * Math.PI * 2 / 24);

                group.name = `aloha`;
                group.add(node)
                group.add(complementaryNode)
            }

            // findType(gltf.scene, 'Mesh')
            // for (var i = 0; i < 20; i++) {
            //     var node = DNANode();
            //     node.position.x = startX + (i * gap);
            //     group.add(getObjectByName('dna_a').geometry)
            // }

            group.onClick = () => { console.log("aloha") }
            group.position.z = -1.8
            scene.add(group)
        },
            // called while loading is progressing
            function (xhr) {

                // console.log((xhr.loaded / xhr.total * 100) + '% loaded');

            },
            // called when loading has errors
            function (error) {

                // console.log('An error happened');

            })
    })


    const getConplementaryDNAStrand = (startingStrand) => {
        const newStrand = startingStrand.map(startingLetter => {
            switch (startingLetter) {
                case "a":
                    return "t";
                    break;
                case "t":
                    return "a";
                    break;
                case "c":
                    return "g";
                    break;
                case "g":
                    return "c";
                    break;
            }
        })

        return newStrand
    }


    return group;
}

export function Heart(props) {
    const { position = [0, 0, 0], color = "red" } = props
    var length = 14,
        width = 2,
        deg = 10,
        thickness = 0.3
    var rad = (deg * Math.PI) / 180
    var offset = Math.min(Math.tan(rad) * width, length / 2)
    var shape = new THREE.Shape()
    // shape.moveTo(0, 0)
    // shape.lineTo(-0.5, 0.5)
    // shape.lineTo(length - offset, width)
    // shape.lineTo(length, 0)
    // shape.lineTo(0, 0)

    const [heartColor, setHeartColor] = useState("red");
    const x = -2.5;
    const y = -5;
    shape.moveTo(x + 2.5, y + 2.5);
    shape.bezierCurveTo(x + 2.5, y + 2.5, x + 2, y, x, y);
    shape.bezierCurveTo(x - 3, y, x - 3, y + 3.5, x - 3, y + 3.5);
    shape.bezierCurveTo(x - 3, y + 5.5, x - 1.5, y + 7.7, x + 2.5, y + 9.5);
    shape.bezierCurveTo(x + 6, y + 7.7, x + 8, y + 4.5, x + 8, y + 3.5);
    shape.bezierCurveTo(x + 8, y + 3.5, x + 8, y, x + 5, y);
    shape.bezierCurveTo(x + 3.5, y, x + 2.5, y + 2.5, x + 2.5, y + 2.5);

    useEffect(() => {
        const { position = [0, 0, 0], color = "red" } = props
        setHeartColor(color);
    }, [color])

    const extrudeSettings = {
        steps: 2,  // ui: steps
        depth: 2,  // ui: depth
        bevelEnabled: true,  // ui: bevelEnabled
        bevelThickness: 1,  // ui: bevelThickness
        bevelSize: 1,  // ui: bevelSize
        bevelSegments: 2,  // ui: bevelSegments
    };

    return (
        <mesh scale={[0.01, 0.01, 0.01]} rotation={[0, 0, 180 * Math.PI / 180]} position={position}>
            <extrudeBufferGeometry attach="geometry" args={[shape, extrudeSettings]} />
            <meshStandardMaterial color={heartColor} side={true} />
        </mesh >
    )
}

export const RNAPolymerase = (props) => {
    const { position = [0, 0, 0], color = "red" } = props
    return (
        <animated.mesh position={position} scale={[1, 0.9, 0.01]}>
            <octahedronBufferGeometry attach="geometry" args={[0.5, 2]} />
            <meshPhongMaterial opacity={0.5} transparent color={color} side={true} />
        </animated.mesh >
    )

}


export function Box({ color, size, scale, children, ...rest }) {
    return (
        <mesh scale={scale} {...rest}>
            <boxBufferGeometry attach="geometry" args={size} />
            <meshPhongMaterial attach="material" color={color} />
            {children}
        </mesh>
    )
}

export function Button({ ...props }) {
    const [hover, setHover] = useState(false)
    const [color, setColor] = useState('blue')

    const { onClick = () => { }, title = "", bgColor = "orange" } = props;
    const onSelect = () => {
        // setColor((Math.random() * 0xffffff) | 0)
        onClick();
    }

    return (
        <Interactive onBlur={() => setHover(false)} onSelect={() => {
            setHover(true);
            onClick();
        }}>
            <Box color={bgColor} onClick={onClick} scale={hover ? [0.6, 0.6, 0.6] : [0.5, 0.5, 0.5]} size={[0.82, 0.22, 0.05]} {...props}>
                <Text position={[0, 0, 0.03]} font="fonts/ttf/fc_condensed.ttf" fontSize={0.12} color="#111" anchorX="center" anchorY="middle"
                    outlineWidth={0.002}
                    outlineColor="#ffffff">
                    {title}
                </Text>
            </Box>
        </Interactive>
    )
}


export function Arrow(props) {
    const { position = [0, 0, 0], color = "red", rotation = [0, 0, 0] } = props
    var length = 14,
        width = 2,
        deg = 10,
        thickness = 0.3
    var rad = (deg * Math.PI) / 180
    var offset = Math.min(Math.tan(rad) * width, length / 2)
    var shape = new THREE.Shape()

    const [arrowColor, setArrowColor] = useState("red");
    const x = position[0];
    const y = position[1];
    shape.moveTo(-0.3, 0.1)
    shape.lineTo(0.1, 0.1)
    shape.lineTo(0.1, 0.2)
    shape.lineTo(0.4, 0)
    shape.lineTo(0.1, -0.2)
    shape.lineTo(0.1, -0.1)
    shape.lineTo(-0.3, -0.1)



    useEffect(() => {
        const { position = [0, 0, 0], color = "red", rotation = [0, 0, 0] } = props
        setArrowColor(color);
    }, [color])

    const extrudeSettings = {
        steps: 2,  // ui: steps
        depth: 0.1,  // ui: depth
        bevelEnabled: true,  // ui: bevelEnabled
        bevelThickness: 0.01,  // ui: bevelThickness
        bevelSize: 0.05,  // ui: bevelSize
        bevelSegments: 2,  // ui: bevelSegments
    };

    return (
        <mesh scale={[0.3, 0.3, 0.3]} {...props}>
            <extrudeBufferGeometry attach="geometry" args={[shape, extrudeSettings]} />
            <meshStandardMaterial color={arrowColor} side={true} />
        </mesh >
    )
}