import React, { useState, Suspense, useRef, useEffect, useImperativeHandle } from 'react'
import * as THREE from "three"
import create from "zustand"
import { DefaultXRControllers, ARCanvas, useXREvent, Interactive, canva } from '@react-three/xr'
import { Text, useGLTF, Html, Billboard, OrbitControls, Environment, Float, Loader, Preload, ContactShadows, Image } from '@react-three/drei'
import { useFrame, useLoader, Canvas } from "@react-three/fiber"
import { useAppStore } from '../../App'
import { useSpring, animated } from '@react-spring/three'
import { PlaneBufferGeometry } from 'three'
import { Mesh } from 'three'
import { Arrow, RNAPolymerase } from '../models/Models'
import { useDrag } from "@use-gesture/react"
import { DNAGameString } from '../models/DNAGameModels'

function DNANode({ ...props }) {
    const [hover, setHover] = useState(false)
    const group = useRef()
    // const [focus, setFocus] = useState(false);
    const { nodes, materials } = useGLTF('3D/dna_game_models.glb');
    const [thisGeom, setThisGeom] = useState();
    const [thisMat, setThisMat] = useState();
    const [thisFocusMat, setThisFocusMat] = useState();


    //a, t, g, c
    const { focus = false, onClick = () => { }, type = "a", active = true } = props;

    const [isFocus, setIsFocus] = useState(false);

    const inactiveMat = new THREE.MeshPhongMaterial({ color: 0x99ff99 })

    useEffect(() => {
        setIsFocus(focus)
    }, [focus])

    let nodeType = type === "u" ? "t" : type;

    useEffect(() => {

        if (thisGeom) {
            setThisGeom(null)
        }
        if (thisMat) {
            setThisMat(null)
        }
        if (thisFocusMat) {
            setThisFocusMat(null)
        }
        if (nodes && nodes[`dna_${nodeType}`] && nodes[`dna_${nodeType}`].geometry && materials && materials[`dna_${nodeType}_mat`]) {
            const _thisGeom = nodes[`dna_${nodeType}`].geometry.clone();
            const _thisMat = materials[`dna_${nodeType}_mat`];
            const _thisFocusMat = _thisMat.clone();
            _thisFocusMat.opacity = 0.5;
            setThisGeom(_thisGeom);
            setThisMat(_thisMat);
            setThisFocusMat(_thisFocusMat);
        }

    }, [nodes, materials])


    if (!thisGeom || !thisMat)
        return null;

    return (
        // <Interactive onSelect={() => setHover(true)} onBlur={() => setHover(false)} onSelect={()=>{
        //     onClick({nodeType, index});
        // }}>
        <animated.group ref={group} {...props} dispose={null}>

            {/* <Box color={isFocus ? "green" : "red"} size={[0.1, 0.6, 0.1]} >
        </Box> */}
            <mesh geometry={thisGeom} material={active ? (isFocus ? thisFocusMat : thisMat) : inactiveMat} />

            <Text position={[0, -0.08, 0.015]} fontSize={0.06} color="#000" anchorX="center" anchorY="middle">
                {type}
            </Text>
            <Text position={[0, -0.08, -0.015]} rotation={[Math.PI, 0, 0]} fontSize={0.06} color="#000" anchorX="center" anchorY="middle">
                {type}
            </Text>
            {/* <mesh geometry={nodes.dna_t.geometry} material={materials.dna_t_mat} />
          <mesh geometry={nodes.dna_g.geometry} material={materials.dna_g_mat} />
          <mesh geometry={nodes.dna_c.geometry} material={materials.dna_c_mat} /> */}
        </animated.group>
        // </Interactive>
    )
}


const getConplementaryDNANode = (startingLetter) => {
    switch (startingLetter) {
        case "a":
            return "t";
            break;
        case "t":
        case "u":
            return "a";
            break;
        case "c":
            return "g";
            break;
        case "g":
            return "c";
            break;
    }
}

function DNAPair({ ...props }) {
    const { type = "a", index = 0, focus = false, onClick = () => { }, active = true, splitDistance = 0 } = props;
    const group = useRef()
    const complementaryType = getConplementaryDNANode(type);
    const [isFocus, setIsFocus] = useState(false);
    const splitNear = 0.01;
    const splitFar = 0.15;

    const [yDist, setYDist] = useState(splitNear)

    const upperDNAProp = useSpring({
        position: [0, -yDist, 0]
    })
    const lowerDNAProp = useSpring({
        position: [0, yDist, 0]
    })
    useEffect(() => {
        setIsFocus(focus)
    }, [focus])

    useEffect(() => {
        const dist = splitDistance * (splitFar - splitNear) + splitNear
        setYDist(dist)
    }, [splitDistance])
    return (
        <Interactive key={`dnaPairInteractive${type}${index}`} onSelect={() => {
            if (active)
                onClick(index);
        }}>
            <group key={`dnaPair${type}${index}`} ref={group} {...props} scale={isFocus ? [1.2, 1.2, 1.2] : [0.9, 0.9, 0.9]} opacity={active ? 1 : 0.5} onClick={(event) => {
                event.stopPropagation();
                if (active) {
                    console.log("click on click")
                    onClick(index)
                }
            }}>
                <DNANode focus={isFocus} key={`dna_${type}_${index}`} type={type} position={upperDNAProp.position} rotation={[0, 0, 0]} active={active} />
                <DNANode focus={isFocus} key={`dnacom_${complementaryType}_${index}`} type={complementaryType} position={lowerDNAProp.position} rotation={[Math.PI, 0, 0]} active={active} />
            </group>
        </Interactive>
    )
}


const DNA = React.forwardRef(({ ...props }, ref) => {
    const { sequence, onCorrect = () => { }, onWrong = () => { }, request, splitPos = 0, useSplit = false } = props
    const group = useRef()
    const startFiveRef = useRef()
    const startThreeRef = useRef()
    const endFiveRef = useRef()
    const endThreeRef = useRef()
    // const rotationStep = 10;
    const rotationStep = 0;
    const gap = 0.15;
    const startPos = -gap * sequence.length / 2;

    const orbitref = useRef()
    const dnaPairRef = useRef()
    const [selected, setSelected] = useState([])
    const [inactives, setInactives] = useState([])
    const playSound = useAppStore(state => state.playSound)

    useImperativeHandle(ref, () => ({
        resetSelections: () => {
            console.log("resetSelections")
            setSelected([]);
            setInactives([]);
        }
    }));

    useEffect(() => {
        return () => {
            if (group.current) {
                group.current.dispose();
            }
        }

    }, [])


    return (
        <animated.group ref={group} {...props}>
            <Billboard
                ref={startFiveRef}
                position={[startPos + -1 * gap, 0.15, 0.06]}
                follow={false}
                lockX={false}
                lockY={false}
                lockZ={false} // Lock the rotation on the z axis (default=false)
            >
                <Text fontSize={0.12} color="black">5'</Text>
            </Billboard>
            <Billboard
                ref={startThreeRef}
                position={[startPos + -1 * gap, -0.15, 0.06]}
                follow={false}
                lockX={false}
                lockY={false}
                lockZ={false} // Lock the rotation on the z axis (default=false)
            >
                <Text fontSize={0.12} color="black">3'</Text>
            </Billboard>
            <Billboard
                ref={endFiveRef}
                position={[startPos + sequence.length * gap + 0.25, -0.15, 0.06]}
                follow={false}
                lockX={false}
                lockY={false}
                lockZ={false} // Lock the rotation on the z axis (default=false)
            >
                <Text font="/fonts/ttf/fc_condensed.ttf" fontSize={0.12} color="black">5' สายแม่แบบ</Text>
            </Billboard>
            <Billboard
                ref={endThreeRef}
                position={[startPos + sequence.length * gap, 0.15, 0.06]}
                follow={false}
                lockX={false}
                lockY={false}
                lockZ={false} // Lock the rotation on the z axis (default=false)
            >
                <Text fontSize={0.12} color="black">3'</Text>
            </Billboard>


            {sequence.map((letter, index) => {
                const isSelected = selected.includes(index)

                const far = 0.7;

                const corespondIndex = Math.floor((((splitPos / far) + 1) / 2) * sequence.length);
                const radian = Math.max(Math.min(Math.PI, (index - corespondIndex) * 20 * Math.PI / 180), -Math.PI)
                const splitDist = Math.max(0, Math.cos(radian))
                return (
                    <DNAPair key={`dnapairsequence_${index}`} splitDistance={splitDist} focus={isSelected} type={letter} index={index} position={[startPos + index * gap, 0, 0.06]} active={!inactives.includes(index)} onClick={(localindex) => {

                        // let newSelected = [];

                        // if (selected.length >= 3)
                        //     return;

                        // if (selected.includes(localindex)) {
                        //     newSelected = [...selected].filter(oldID => oldID !== localindex)
                        // }
                        // else {
                        //     newSelected = [...selected]
                        //     newSelected.push(localindex);
                        // }

                        // console.log("onClick")
                        // playSound("touch")

                        // async function checkResult() {

                        //     console.log("checkResult")
                        //     await setTimeout(() => {
                        //         const orderedSelected = newSelected.sort((a, b) => (a - b));

                        //         if (!((orderedSelected[2] - orderedSelected[1]) == 1 && (orderedSelected[1] - orderedSelected[0]) == 1)) {

                        //             playSound("lose");
                        //             setSelected([])
                        //             onWrong();
                        //         }
                        //         else {

                        //             const joinedSelected = orderedSelected.map((sequenceIndex) => sequence[sequenceIndex]).join("");

                        //             const startCodons = ["aug"];
                        //             const stopCodons = ["uaa", "uag", "uga"];
                        //             if (startCodons.includes(joinedSelected)) {
                        //                 playSound("win");
                        //                 setSelected([])
                        //                 setInactives([...inactives, ...orderedSelected])
                        //                 onCorrect();
                        //             }
                        //             else if (stopCodons.includes(joinedSelected)) {
                        //                 playSound("win");
                        //                 setSelected([])
                        //                 setInactives([...inactives, ...orderedSelected])
                        //                 onCorrect();
                        //             }
                        //             else {
                        //                 playSound("lose");
                        //                 setSelected([])
                        //                 onWrong();
                        //             }
                        //         }

                        //     }, 500);

                        // }

                        // if (newSelected.length >= 3) {
                        //     setSelected(newSelected)
                        //     checkResult()
                        // }
                        // else {
                        //     setSelected(newSelected)
                        // }
                    }} rotation={[Math.PI * index * rotationStep / 180, 0, 0]} />

                )
            })}
        </animated.group>
    )
})


export function Stage2(props) {
    const textref = useRef();
    const dnaref = useRef();
    const dnaPolyref = useRef();
    const playSound = useAppStore(state => state.playSound)

    const { gameData = null, onAnswered, totalQuestionsPerGameStage = 3 } = props;
    const [qSequence, setQSequence] = useState(null)
    const [clearDNA, setClearDNA] = useState(false)
    const [polyPos, setPolyPos] = useState(0);
    const dnaDistance = 2;
    const [correctCount, setCorrectCount] = useState(0);

    const dnaProps = useSpring({ scale: clearDNA ? [0, 0, 0] : [1, 1, 1], position: [0, -0.1, -0.5] })
    const dnaPolyProps = useSpring({ position: [polyPos * dnaDistance, 0, 0] })

    const RightSplit = [13, 22]
    const midSplit = [5, 14]
    const leftSplit = [-3, 6]

    const [spliteRange, setSpliteRange] = useState([5, 14]);

    var questionsSet = [
        ["a", "u", "g", "g", "a", "c", "u", "a", "g", "u", "c", "a", "g", "u", "c", "a", "c", "g", "a"],
        ["g", "a", "u", "g", "a", "c", "u", "a", "g", "u", "c", "a", "g", "u", "c", "a", "c", "g", "a"],
        ["t", "u", "a", "u", "g", "c", "u", "c", "g", "u", "g", "a", "g", "u", "c", "a", "c", "g", "a"],
        // ["a", "t", "g", "g", "a", "c", "u", "a", "g", "u", "c", "a", "g", "u", "c", "a", "c", "g", "a"],
        // ["c", "u", "t", "g", "a", "c", "u", "a", "g", "u", "c", "a", "g", "u", "c", "a", "c", "g", "a"],
        // ["g", "u", "c", "g", "a", "c", "u", "a", "g", "u", "c", "a", "g", "u", "c", "a", "c", "g", "a"]
    ]

    const getNewQuestion = async () => {
        const newQ = questionsSet.sort(() => (Math.random() > 0.5 ? -1 : 1)).pop();
        setQSequence(newQ)

        setClearDNA(true)
        // if (dnaref.current) {
        //     dnaref.current.resetSelections();
        // }
        setTimeout(() => {
            setClearDNA(false)
        }, 1000)

        setPolyPos(0)
        setSpliteRange(midSplit);
    }


    const [{ x, y }, api] = useSpring(() => ({ x: 0, y: 0 }))



    useEffect(() => {

        if (polyPos >= 0.7) {

            setTimeout(() => {
                onCorrect();
            }, 500)
        }
        else if (polyPos <= -0.7) {

            setTimeout(() => {
                onWrong();
            }, 500)
        }
    }, [polyPos])

    useEffect(() => {
        questionsSet = [
            ["a", "u", "g", "g", "a", "c", "u", "a", "g", "u", "c", "a", "g", "u", "c", "a", "c", "g", "a"],
            ["g", "a", "u", "g", "a", "c", "u", "a", "g", "u", "c", "a", "g", "u", "c", "a", "c", "g", "a"],
            ["t", "u", "a", "u", "g", "c", "u", "c", "g", "u", "g", "a", "g", "u", "c", "a", "c", "g", "a"],
            // ["a", "t", "g", "g", "a", "c", "u", "a", "g", "u", "c", "a", "g", "u", "c", "a", "c", "g", "a"],
            // ["c", "u", "t", "g", "a", "c", "u", "a", "g", "u", "c", "a", "g", "u", "c", "a", "c", "g", "a"],
            // ["g", "u", "c", "g", "a", "c", "u", "a", "g", "u", "c", "a", "g", "u", "c", "a", "c", "g", "a"]
        ]
        getNewQuestion()

        setCorrectCount(0)
        setSpliteRange(midSplit);
    }, [])

    if (!qSequence) {
        return null;
    }

    const onCorrect = () => {
        setCorrectCount(correctCount + 1)
        console.log("correctCount " + correctCount)
        console.log("totalQuestionsPerGameStage " + totalQuestionsPerGameStage)


        playSound("victory")
        onAnswered(1, true, getNewQuestion, true)
        // getNewQuestion()
    }


    const onWrong = () => {
        onAnswered(1, false, getNewQuestion, true)
    }


    const onPress = (direction) => {

        switch (direction) {
            case "right":
                setPolyPos(polyPos + 0.7)
                setSpliteRange(RightSplit);
                break;
            case "left":
                setPolyPos(polyPos - 0.7)
                setSpliteRange(leftSplit);
                break;
        }
        console.log("on Press " + direction)
    }

    // console.log("dnaSplitProps.start " + Math.round(parseFloat(JSON.stringify(dnaSplitProps.pos) + 5)))
    return (
        <>
            {/* <DNA ref={dnaref} splitPos={polyPos} useSplit {...dnaProps} sequence={qSequence} onCorrect={onCorrect} onWrong={onWrong} /> */}

            <DNAGameString mArray={qSequence} rotationStep={0} nonSpinRange={spliteRange} toHideTop={[]} selectedPairs={[]} toHideBottom={[]} toHidePair={[]} toHideGRoup={[]} onBaseClicked={() => { }}></DNAGameString>
            <RNAPolymerase ref={dnaPolyref} {...dnaPolyProps}></RNAPolymerase>

            <Interactive key={`dnaArrowRight`} onSelect={() => {
                onPress("right");
            }}>
                <Arrow position={[0.15, -0.5, 0]} onPointerDown={(event) => {
                    // event.stopPropagation();
                    onPress("right")
                }} rotation={[0, 0, 0]}></Arrow>
            </Interactive>
            <Interactive key={`dnaArrowLeft}`} onSelect={() => {
                onPress("left");
            }}>
                <Arrow position={[-0.15, -0.5, 0]} onPointerDown={(event) => {
                    // event.stopPropagation();
                    onPress("left")
                }} rotation={[0, 0, Math.PI]}></Arrow>
            </Interactive>
            {/* <group position={[0, 0, 0]}>
        <mesh>
          <planeBufferGeometry attach="geometry" args={[2, 1]} ></planeBufferGeometry>
          <meshStandardMaterial attach="material" color={"grey"} side={true} />
        </mesh>
        <Text fontSize={0.12} color="black" position={[0, 0, 0.1]}>GAME OVER</Text>
      </group> */}
        </>
    )
}
