import React, { useEffect, useState } from 'react';
import create from 'zustand'
import { useAppStore, loadPretestData, loadStages } from '../App';
import CoverPage from './CoverPage';
import PreKnowledgePage from './PreKnowledgePage';
import TutorialPage from './TutorialPage';
import { useNavigate } from "react-router-dom";
import { AbstractStage } from '../threejs/scenes/AbstractStage';
import WrittenQuestionPage from './WrittenQuestionPage';
import MultipleChoiceQuestionPage from './MultipleChoiceQuestionPage';
import EndingPage from './EndingPage';
import { authPostRequest } from '../utils/server';


export const useStageStore = create(set => ({
    currentStage: null,
    currentStep: 0,
    gameAttempts: [],
    writtenAnswer: "",
    choiceAnswer: "",
    choiceResult: null,
    gameResult: null,
    currentGameStageIndex: 0,
    clearResult: () => {
        set(state => ({ choiceAnswer: "", choiceResult: null, gameResult: null, writtenAnswer: "", gameAttempts: [] }))
    },
    nextStep: () => {
        set(state => ({ currentStep: state.currentStep + 1 }))
    },
    nextGameStage: () => {
        set(state => ({ currentGameStageIndex: state.currentGameStageIndex + 1 }))
    },
    prevStep: () => {
        set(state => ({ currentStep: state.currentStep > 0 ? state.currentStep - 1 : 0 }))
    },
    restart: () => {
        set(state => ({ currentStep: 0 }))
    },
    setCurrentStage: (stageData) => {
        set(state => ({ currentStep: 1, currentStage: stageData }))
    },
    setCurrentStep: (step) => {
        set(state => ({ currentStep: step }))
    },

    pushGameAttempt: (attemptResult) => {

        set(state => ({ gameAttempts: [...state.gameAttempts, attemptResult] }))
    },
    resetGameAttempts: (attemptResult) => {

        set(state => ({ gameAttempts: [] }))
    },
    answerWritten: (answer) => {
        set(state => ({ writtenAnswer: answer }))
    },
    resetWrittenAnswer: () => {
        set(state => ({ writtenAnswer: "" }))
    },
    answerChoice: (answer, result) => {
        set(state => ({ choiceAnswer: answer, choiceResult: result }))
    },
    resetChoicAnswer: () => {
        set(state => ({ choiceAnswer: "", choiceResult: null }))
    },
    nextPretest: () => set(state => ({ currentPretestIndex: state.currentPretestIndex + 1 })),
    setGameResult: (result) => set(state => ({ gameResult: result })),

}))

const StageTemplate = ({ children, stageID, ingameRefScale = [1, 1, 1], totalQuestionsPerGameStage = 3 }) => {

    let navigate = useNavigate();
    const stages = useAppStore(state => state.stages)
    const stopBGSound = useAppStore(state => state.stopBGSound)
    const playBGSound = useAppStore(state => state.playBGSound)
    const setCurrentStage = useStageStore(state => state.setCurrentStage)
    const setCurrentStep = useStageStore(state => state.setCurrentStep)
    const currentStage = useStageStore(state => state.currentStage)
    const updateStages = useStageStore(state => state.updateStages)
    const currentStep = useStageStore(state => state.currentStep)
    const currentGameStageIndex = useStageStore(state => state.currentGameStageIndex)
    const restart = useStageStore(state => state.restart)
    const [contentModule, setContentModule] = useState(null);

    useEffect(async () => {

        if (!stages)
            return

        const currentStageData = stages.find((stageData) => stageData.id === stageID);

        if (currentStageData) {
            setCurrentStage(currentStageData)
            setCurrentStep(1)
        }
    }, [stages])

    // useEffect(() => {

    //     if (stageID === 6 && currentStep === 4) {

    //         const currentGameStageID = (currentGameStageIndex + 1)
    //         const currentStageData = stages.find((stageData) => `${stageData.id}` === `${currentGameStageID}`);

    //         if (currentStageData) {
    //             setCurrentStage(currentStageData)
    //             setCurrentStep(1)
    //         }
    //     }

    //     console.log("currentStageData " + JSON.stringify(stages))
    // }, [currentGameStageIndex, stages])


    useEffect(async () => {


        if (!stages) {
            return () => {
                console.log("unmound stage template 1")
                setCurrentStep(1);
                setContentModule(null);
            }
        }

        const currentStageData = stages.find((stageData) => stageData.id === stageID);

        playBGSound("menu")
        if (currentStageData) {
            setCurrentStage(currentStageData)
            setCurrentStep(1)
        }
        return () => {
            console.log("unmound stage template 2")
            setCurrentStep(1);
            setContentModule(null);
        }
    }, [])


    useEffect(async () => {


        var cModule = null;
        switch (currentStep) {
            case 0:
                // navigate("../menu", { replace: true });
                break;
            case 1:
                playBGSound("tutorial");
                cModule = <CoverPage key={`scene1_${stageID}`}></CoverPage>
                break;
            case 2:
                cModule = <PreKnowledgePage key={`scene2_${stageID}`}></PreKnowledgePage>
                break;
            case 3:
                cModule = <TutorialPage key={`scene3_${stageID}`}></TutorialPage>
                break;
            case 4:
                console.log(`useEffect AbstractStage`)
                playBGSound(`gameplay`);
                cModule = <AbstractStage key={`scene4_${stageID}`} ingameRefScale={ingameRefScale} totalQuestionsPerGameStage={(stageID === 6) ? 1 : totalQuestionsPerGameStage} gameData={currentStage}>{(stageData, onAnswered) => children(stageData, onAnswered, totalQuestionsPerGameStage)}</AbstractStage>;
                break;
            case 5:
                cModule = <WrittenQuestionPage key={`scene5_${stageID}`}></WrittenQuestionPage>;
                break;
            case 6:
                cModule = <MultipleChoiceQuestionPage key={`scene6_${stageID}`}></MultipleChoiceQuestionPage>;
                break;
            case 7:
                cModule = <EndingPage key={`scene7_${stageID}`}></EndingPage>;
                break;
            default:
                cModule = null;
                break;
        }

        console.log(cModule)
        setContentModule(cModule)

    }, [currentStep])

    if (!currentStage) {
        return null
    }


    return (
        <div style={{ width: "100%", height: "100%", margin: 0, padding: 0 }}>
            {
                contentModule
            }
        </div>
    )
}

export default StageTemplate