import React from 'react';
import { SERVER_URL } from '../Config';
import { useStageStore } from './StageTemplate';
import "../interface.scss";
import { Link } from 'react-router-dom';


function CoverPage() {

    const currentStage = useStageStore(state => state.currentStage)
    const nextStep = useStageStore(state => state.nextStep)
    const prevStep = useStageStore(state => state.prevStep)

    if (!currentStage)
        return null

    return (
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", height: "100%" }}>
            {currentStage.cover_image ? <img src={SERVER_URL + currentStage.cover_image.url} style={{ width: "85%", maxWidth: "640px", height: "auto", maxHeight: "480px", objectFit: "cover", margin: "20px", borderRadius: "20px" }}></img> : null}
            <span className="dnaHeader" style={{ textAlign: "center" }}>{currentStage.name}</span>
            <span className="dnaSubHeader" style={{ textAlign: "center", marginBottom: "20px" }}>{currentStage.topic}</span>
            <div>

                <Link to="/menu" className="btn blue">กลับหน้าเมนู</Link>
                <a onClick={nextStep} className="btn orange">ไปต่อ</a>
            </div>
        </div>
    );
}

export default CoverPage;