import { createContext, useState, useEffect, useContext } from 'react';
// import { auth } from '../utils/firebase';
import Cookies from 'js-cookie';
import { encrypt, decrypt } from '../utils/crypto';
import { SERVER_URL } from '../Config';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';

const AuthContext = createContext();


export function useAuth() {
    return useContext(AuthContext);
}

export const AuthProvider = (props) => {

    const [user, setUser] = useState()
    const [userProfile, setUserProfile] = useState()
    const [loading, setLoading] = useState(true)


    const updateUserProfile = async (params) => {
        const t = await getToken();

        if (!t)
            return null;

        const result = await updateProfile(params, t);
        if (result) {
            setUserProfile(result)
        }
        return result;
    }

    const updateProfile = async (params, token) => {
        // try {
        //     setLoading(true);
        //     const response = await fetch(`${SERVER_URL}/user-profiles`, {
        //         headers: {
        //             'Accept': 'application/json',
        //             'Content-Type': 'application/json',
        //             'Authorization': `Bearer ${token}`
        //         },
        //         method: "POST",
        //         body: JSON.stringify(params)
        //     })

        //     const resultObj = await response.json();

        //     if (resultObj.message) {

        //     }
        //     else {
        //         setUserProfile(resultObj)
        //         await sleep(3000)
        //     }

        //     setLoading(false);
        //     return (resultObj)
        //     // router.push('/');
        // } catch (err) {
        //     setUserProfile(null);
        //     await sleep(3000)
        //     setLoading(false);
        //     return { message: [{ messages: [{ message: "Failed to update user profile" }] }] }
        // }
    }

    const refreshUserProfile = async () => {

        if (user) {
            const t = await getToken();
            const responseObj = retrieveUserProfile(t);

            if (responseObj.message) {

            }
            else {
                setUserProfile(responseObj);
            }
        }
        else {
            setUserProfile(null);
        }
    }
    const retrieveUserProfile = async (token) => {
        // try {
        //     setLoading(true);
        //     const response = await fetch(`${SERVER_URL}/user-profiles`, {
        //         headers: {
        //             'Accept': 'application/json',
        //             'Content-Type': 'application/json',
        //             'Authorization': `Bearer ${token}`
        //         },
        //         method: "GET"
        //     })

        //     const resultObj = await response.json();

        //     if (resultObj.message) {
        //         setUser(null);
        //         clearUser();
        //     }
        //     else {
        //         setUserProfile(resultObj)
        //     }

        //     setLoading(false);
        //     return (resultObj)
        //     // router.push('/');
        // } catch (err) {
        //     setUserProfile(null);
        //     setLoading(false);
        //     return { message: [{ messages: [{ message: "Failed to retrieve user profile" }] }] }
        // }
    }

    const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const createUpdateUser = async (userObject, news_subscription = true) => {

        if (!userObject || (userObject && "message" in userObject)) {
            console.log("createUpdateUser no user")
            return null
        }
        else {
            setUser(userObject)
            console.log("createUpdateUser setting user " + JSON.stringify(userObject))
            await sleep(500);
            // console.log("successfully created user " + JSON.stringify(resultObj))
            const regToken = userObject.jwt
            const response2 = await updateProfile({ news_subscription: news_subscription }, regToken);

            if (response2.message) {
                return null
            }

            return response2
        }
    }
    const signup = async (username, email, password) => {

        try {
            setLoading(true);
            const response = await fetch(`${SERVER_URL}/auth/local/register`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify({ username, email, password })
            })

            const resultObj = await response.json();

            if (resultObj.message && resultObj.message.length) {
                // resultObj.message.forEach(element => {
                //     if (element.messages && element.messages.length) {
                //         element.messages.forEach(element2 => {
                //             Modal.error({ title: element2.message })
                //         })
                //     }
                // });
            }
            else {
                setUser(resultObj)
                // console.log("successfully created user " + JSON.stringify(resultObj))
                // const regToken = resultObj.jwt
                // const response2 = await updateProfile({ news_subscription: news_subscription }, regToken);

                // if (response2.message) {

                // }
            }

            return resultObj;
            setLoading(false);
            return (resultObj)
            // router.push('/');
        } catch (err) {
            setUser(null);
            clearUser();
            setLoading(false);
            return { message: [{ messages: [{ message: "Failed to create user" }] }] }
        }

    }
    const login = async (email, password) => {

        try {
            setLoading(true);
            const response = await fetch(`${SERVER_URL}/auth/local`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify({ identifier: email, password })
            })

            const resultObj = await response.json();
            // console.log("login success " + JSON.stringify(resultObj))
            if (resultObj.message) {

            }
            else {
                const { id, username, email, provider, confirmed, blocked, role, created_at, updated_at } = resultObj.user;
                const truncatedUser = { id, username, email, provider, confirmed, blocked, role, created_at, updated_at };
                const truncatedUserObj = { jwt: resultObj.jwt, user: truncatedUser }
                setUser(truncatedUserObj)
            }
            setLoading(false);

            return resultObj
            // router.push('/');
        } catch (err) {

            console.log("login err " + err)
            setUser(null);
            clearUser();
            setLoading(false);

            return { message: [{ messages: [{ message: "Failed to log in" }] }] }
        }
        // return auth.signInWithEmailAndPassword(email, password)
    }
    const logout = () => {

        try {
            // await magic.user.logout()
            setUser(null);
            clearUser();

        } catch (err) {
            console.log(err)
        }
        // return auth.signOut();
    }



    const checkUserLoggedIn = async () => {
        try {
            // const isLoggedIn = await magic.user.isLoggedIn()

            // if (isLoggedIn) {
            //     const { email } = await magic.user.getMetadata();
            //     setUser({ email })
            // }


            // //just for testing
            // const token = await getToken();
            // console.log("checkUserLoggedIn token ", token)

            loadUser();
        }
        catch (err) {

        }
    }

    const getToken = async () => {

        // console.log("getToken " + JSON.stringify(user));
        try {
            return user.jwt
            // const token = await auth.currentUser.getIdToken();
            // return token
        }
        catch (err) {
            return null
        }
    }

    useEffect(() => {
        checkUserLoggedIn();
    }, [])

    const saveUser = () => {
        console.log("saveUser")
        if (user) {

            const toSaveUser = { ...user }

            console.log("user " + JSON.stringify(toSaveUser))
            const encrypted = encrypt(JSON.stringify(toSaveUser));

            console.log("encrypted " + encrypted)
            Cookies.set("dnagameclient", encrypted)
        }
        else {
            Cookies.set("dnagameclient", null)
        }
    }

    const clearUser = () => {
        // console.log("clearUser")
        Cookies.set("dnagameclient", null)
    }
    const loadUser = () => {
        console.log("loadUser")
        if (Cookies.get("dnagameclient") && (Cookies.get("dnagameclient") !== "null")) {
            const clientString = Cookies.get("dnagameclient");
            const decrypted = decrypt(clientString);

            console.log("clientString " + clientString)
            console.log("decrypted " + decrypted)
            if (decrypted) {
                setUser(JSON.parse(decrypted))
            }


        }
        else {
            logout();
        }
        setLoading(false);
    }

    const onUserUpdated = async () => {

        // console.log("onUserUpdated ")
        if (user) {
            const t = await getToken();
            await retrieveUserProfile(t);
            saveUser();
        }
    }

    useEffect(() => {
        if (user !== undefined) {
            console.log("user effect " + user)
            onUserUpdated();
        }
    }, [user])

    const value = {
        user,
        signup,
        login,
        logout,
        loadUser,
        getToken,
        setUser,
        loading,
        setLoading,
        userProfile,
        createUpdateUser,
        updateUserProfile,
        refreshUserProfile
    }

    return (
        <AuthContext.Provider value={value}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContext