import React, { useEffect, useState } from 'react';
import { Row, Col, Radio, Input, Space, Button } from 'antd';
import { useAppStore } from '../App';
// import { usePretest } from '../context/PretestContext';

function PretestQuestionModule(props) {

    const { pretestQuestionData = {}, onAnswered = null } = props;

    const nextPretest = useAppStore(state => state.nextPretest);
    const pretestData = useAppStore(state => state.pretestData);
    const currentPretestIndex = useAppStore(state => state.currentPretestIndex);
    const backToPreviousPretest = useAppStore(state => state.backToPreviousPretest);
    const answerPretest = useAppStore(state => state.answerPretest);
    const [value, setValue] = useState(null);


    const { id, question, ordering, answer_choice_id, choice = [] } = pretestQuestionData

    // const { reset, back, answerCurrentPretest } = usePretest();

    const onChange = (e) => {
        // console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    useEffect(async () => {

    }, [value])

    const confirmAnswer = async () => {
        if (value) {

            await setTimeout(() => {
                setValue(null);
                if (onAnswered) {
                    // console.log("onAnswered " + value)
                    onAnswered(currentPretestIndex, id, value, value === answer_choice_id);
                }
                else
                    answerPretest(currentPretestIndex, id, value, value === answer_choice_id)
            }, 250)
        }
    }

    const back = () => {
        backToPreviousPretest();
    }
    // console.log("pretestData" + JSON.stringify(pretestQuestionData))
    return (
        <div style={{ padding: "0", height: "100%" }}>
            <Row style={{ padding: "20px 40px", backgroundColor: "#ddd", borderRadius: "30px", height: "100%" }}>
                <Col span={24} style={{ margin: "16px 0", marginLeft: "1.1em", textIndent: "-1.1em", fontSize: "21px", lineHeight: "28px", fontWeight: 600, display: "flex", alignItems: "center", textAlign: "inherit", justifyContent: "center" }}>
                    {`${id}. ${question}`}
                </Col>
                <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
                    <Radio.Group size='large' onChange={onChange} value={value}>
                        {/* <Space direction="vertical"> */}
                        {
                            choice.sort((a, b) => {
                                return a.ordering - b.ordering;
                            }).map((c, index) => {
                                const { choice_id, content, id, ordering, decorations = [] } = c;
                                const choiceTitle = choice_id.replace("choice_", "").toLocaleUpperCase() + ".";
                                return (
                                    <Col key={`choice_${index}`} span={24} style={{ fontSize: "22px", fontFamily: "Kanit", fontWeight: 500 }}>
                                        <Radio key={`${id}_${choice_id}`} value={choice_id}>{`${content}`}</Radio>
                                    </Col>
                                )
                            })
                        }
                        {/* </Space> */}
                    </Radio.Group>
                </Col>

                <Col span={24} style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "12px" }}>
                    <a className={(currentPretestIndex === 0) ? "btn orange disabled" : "btn orange"} onClick={back}>กลับ</a>
                    <a className={value ? "btn blue" : "btn blue disabled"} onClick={confirmAnswer}>ยืนยัน</a>
                </Col>
            </Row>
            {/* <Row style={{ margin: "20px 0" }} justify="end">
                <Col>
                    <Button type="primary" size='large' shape="round" onClick={() => {
                        if (value !== null && (currentPretestIndex < pretestData.length)) {
                            setValue(null);
                            nextPretest();
                        }
                    }}>ข้อถัดไป</Button>
                </Col>
            </Row> */}
        </div >

    );
}

export default PretestQuestionModule;