import React, { useCallback, useEffect, useRef } from 'react';
import { SERVER_URL } from '../Config';
import { useStageStore } from './StageTemplate';
import "../interface.scss";
import { Link } from 'react-router-dom';
import ReactCanvasConfetti from "react-canvas-confetti";
import { useAuth } from '../context/AuthContext';
import { authPostRequest } from '../utils/server';
import { useAppStore } from '../App';
const canvasStyles = {
    position: "fixed",
    pointerEvents: "none",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0
};

function EndingPage() {

    const { user } = useAuth();
    const playSound = useAppStore(state => state.playSound)
    const currentStage = useStageStore(state => state.currentStage)
    const nextStep = useStageStore(state => state.nextStep)
    const prevStep = useStageStore(state => state.prevStep)
    const writtenAnswer = useStageStore(state => state.writtenAnswer)
    const choiceAnswer = useStageStore(state => state.choiceAnswer)
    const choiceResult = useStageStore(state => state.choiceResult)
    const gameAttempts = useStageStore(state => state.gameAttempts)
    const gameResult = useStageStore(state => state.gameResult)
    const clearResult = useStageStore(state => state.clearResult)

    const refAnimationInstance = useRef(null);

    const getInstance = useCallback((instance) => {

        refAnimationInstance.current = instance;
    }, []);

    useEffect(async () => {

        const resultJSON = prepareStageResult();
        if (!resultJSON)
            return;

        console.log(JSON.stringify(resultJSON))
        await authPostRequest("stage-results", resultJSON, user.jwt);
        clearResult();
        fire();
        playSound("clap")
    }, [])

    useEffect(() => {
        console.log("useeffect user " + user)
    }, [user])

    const prepareStageResult = () => {
        if (!gameResult && gameAttempts.length === 0)
            return null;

        var result = {}

        result.game_attempts = JSON.stringify(gameAttempts);
        result.written_answer = writtenAnswer;
        result.multiple_choice_answer = choiceAnswer;
        result.multiple_choice_result = choiceResult;
        result.game_result = gameResult;
        result.stage = currentStage.id;

        return result;
    }

    const makeShot = useCallback((particleRatio, opts) => {
        refAnimationInstance.current &&
            refAnimationInstance.current({
                ...opts,
                origin: { y: 0.7 },
                particleCount: Math.floor(200 * particleRatio)
            });
    }, []);

    const fire = useCallback(() => {
        makeShot(0.25, {
            spread: 26,
            startVelocity: 55
        });

        makeShot(0.2, {
            spread: 60
        });

        makeShot(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8
        });

        makeShot(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2
        });

        makeShot(0.1, {
            spread: 120,
            startVelocity: 45
        });
    }, [makeShot]);


    if (!currentStage)
        return null


    return (
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", height: "100%", padding: "20px" }}>
            <span className="dnaHeader" style={{ textAlign: "center", marginBottom: "20px" }}>{`เก่งมาก คุณพิชิต ${currentStage.name} ได้แล้ว`}</span>
            <div>
                {currentStage.id === 6 ? (<Link to={{ pathname: "/pretest/true" }}><a className="btn blue" style={{ fontSize: "18px", textAlign: "center" }}>ทำแบบทดสอบหลังการเรียนรู้<br />เพื่อเสร็จสิ้นกิจกรรม</a></Link>) : (<Link to="/menu"><a className="btn blue" style={{ fontSize: "18px" }}>กลับสู่เมนู</a></Link>)}

            </div>
            <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />

        </div>
    );
}

export default EndingPage;