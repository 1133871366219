import { Form, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SERVER_URL } from '../../Config';
import { JsonToExcel } from "react-json-to-excel";

function ExportTestResultsPage(props) {

    const [stageResults, setStageResults] = useState(null);
    const [pretestAnswers, setPretestAnswers] = useState(null);
    const [posttestAnswers, setPosttestAnswers] = useState(null);
    const [prePostScores, setPrePostScores] = useState(null);

    useEffect(() => {
        getStageResult();
        getPretest();
        getPosttest();
        getPrePostScores();
    }, [])

    const getPrePostScores = async () => {
        const preResponse = await fetch(`${SERVER_URL}/pretest-answers?is_post_test=false&_limit=100000`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "GET"
        })
        const preResultObj = await preResponse.json();
        // console.log(JSON.stringify(preResultObj))

        const postResponse = await fetch(`${SERVER_URL}/pretest-answers?is_post_test=true&_limit=100000`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "GET"
        })

        const postResultObj = await postResponse.json();


        const formattedResults = preResultObj.map((rObj) => {

            const postMatch = postResultObj ? postResultObj.find((postObj) => {
                const preUserID = rObj.user ? rObj.user.id : "-2";
                const postUserID = (postObj ? (postObj.user ? postObj.user.id : "-1") : "-1");
                // console.log("compare preUserID " + preUserID)
                // console.log("compare postUserID " + postUserID)
                return preUserID === postUserID;
            }) : null;

            var preScore = 0;
            var postScore = 0;

            for (var i = 0; i < 10; i++) {
                if (rObj[`pretest${i + 1}_result`] === true) {
                    preScore++;
                }
            }

            if (postMatch) {

                for (var i = 0; i < 10; i++) {
                    if (postMatch[`pretest${i + 1}_result`] === true) {
                        postScore++;
                    }
                }
            }
            return {
                // ...rObj, 
                user_id: rObj.user ? rObj.user.id : null,
                user_name: rObj.user ? rObj.user.username : null,
                user_email: rObj.user ? rObj.user.email : null,
                pretest_id: rObj.id,
                posttest_id: postMatch ? postMatch.id : null,
                pretest_score: preScore,
                posttest_score: postScore,
                published_at: rObj.published_at,
                created_at: rObj.created_at,
                updated_at: rObj.updated_at
            }
        })
        setPrePostScores(formattedResults)
    }

    const getStageResult = async () => {
        const response = await fetch(`${SERVER_URL}/stage-results?_limit=100000`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "GET"
        })
        const resultObj = await response.json();
        const formattedResults = resultObj.map((rObj) => ({
            // ...rObj, 
            id: rObj.id,
            user_id: rObj.user ? rObj.user.id : null,
            user_name: rObj.user ? rObj.user.username : null,
            user_email: rObj.user ? rObj.user.email : null,
            stage_id: rObj.stage ? rObj.stage.id : null,
            stage_name: rObj.stage ? rObj.stage.name : null,
            game_attempts: rObj.game_attempts,
            written_answer: rObj.written_answer,
            multiple_choice_answer: rObj.multiple_choice_answer,
            multiple_choice_result: rObj.multiple_choice_result,
            game_result: rObj.game_result,
            published_at: rObj.published_at,
            created_at: rObj.created_at,
            updated_at: rObj.updated_at
        }))
        // const formattedResults = resultObj.map((rObj) => ({ 
        //     ...rObj, 
        //     user_id: rObj.user ? rObj.user.id : null, 
        //     user: rObj.user ? rObj.user.username : null, 
        //     user_email: rObj.user ? rObj.user.email : null, 
        //     stage_id: rObj.stage ? rObj.stage.id : null, 
        //     stage: rObj.stage ? rObj.stage.name : null }))

        console.log(JSON.stringify(formattedResults))
        setStageResults(formattedResults)
    }
    const getPretest = async () => {
        const response = await fetch(`${SERVER_URL}/pretest-answers?is_post_test=false&_limit=100000`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "GET"
        })
        const resultObj = await response.json();
        // console.log(JSON.stringify(resultObj))

        const formattedResults = resultObj.map((rObj) => ({
            // ...rObj, 
            id: rObj.id,
            user_id: rObj.user ? rObj.user.id : null,
            user_name: rObj.user ? rObj.user.username : null,
            user_email: rObj.user ? rObj.user.email : null,
            pretest1_answer: rObj.pretest1_answer,
            pretest2_answer: rObj.pretest2_answer,
            pretest3_answer: rObj.pretest3_answer,
            pretest4_answer: rObj.pretest4_answer,
            pretest5_answer: rObj.pretest5_answer,
            pretest6_answer: rObj.pretest6_answer,
            pretest7_answer: rObj.pretest7_answer,
            pretest8_answer: rObj.pretest8_answer,
            pretest9_answer: rObj.pretest9_answer,
            pretest10_answer: rObj.pretest10_answer,
            pretest1_result: rObj.pretest1_result,
            pretest2_result: rObj.pretest2_result,
            pretest3_result: rObj.pretest3_result,
            pretest4_result: rObj.pretest4_result,
            pretest5_result: rObj.pretest5_result,
            pretest6_result: rObj.pretest6_result,
            pretest7_result: rObj.pretest7_result,
            pretest8_result: rObj.pretest8_result,
            pretest9_result: rObj.pretest9_result,
            pretest10_result: rObj.pretest10_result,
            stages_to_play: rObj.stages_to_play,
            is_post_test: rObj.is_post_test,
            published_at: rObj.published_at,
            created_at: rObj.created_at,
            updated_at: rObj.updated_at
        }))
        setPretestAnswers(formattedResults)
    }

    const getPosttest = async () => {
        const response = await fetch(`${SERVER_URL}/pretest-answers?is_post_test=true&_limit=100000`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "GET"
        })

        const resultObj = await response.json();
        // console.log(JSON.stringify(resultObj))
        const formattedResults = resultObj.map((rObj) => ({
            // ...rObj, 
            id: rObj.id,
            user_id: rObj.user ? rObj.user.id : null,
            user_name: rObj.user ? rObj.user.username : null,
            user_email: rObj.user ? rObj.user.email : null,
            pretest1_answer: rObj.pretest1_answer,
            pretest2_answer: rObj.pretest2_answer,
            pretest3_answer: rObj.pretest3_answer,
            pretest4_answer: rObj.pretest4_answer,
            pretest5_answer: rObj.pretest5_answer,
            pretest6_answer: rObj.pretest6_answer,
            pretest7_answer: rObj.pretest7_answer,
            pretest8_answer: rObj.pretest8_answer,
            pretest9_answer: rObj.pretest9_answer,
            pretest10_answer: rObj.pretest10_answer,
            pretest1_result: rObj.pretest1_result,
            pretest2_result: rObj.pretest2_result,
            pretest3_result: rObj.pretest3_result,
            pretest4_result: rObj.pretest4_result,
            pretest5_result: rObj.pretest5_result,
            pretest6_result: rObj.pretest6_result,
            pretest7_result: rObj.pretest7_result,
            pretest8_result: rObj.pretest8_result,
            pretest9_result: rObj.pretest9_result,
            pretest10_result: rObj.pretest10_result,
            stages_to_play: rObj.stages_to_play,
            is_post_test: rObj.is_post_test,
            published_at: rObj.published_at,
            created_at: rObj.created_at,
            updated_at: rObj.updated_at
        }))
        setPosttestAnswers(formattedResults)
    }


    return (
        <div style={{ marginTop: "20px", display: "flex", width: "100%", height: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>

            <div className='dnaHeader' style={{ marginBottom: "20px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                {"ดาวน์โหลดข้อมูลการเล่น"}
            </div>
            <Form><Form.Item
                wrapperCol={{
                    span: 24,
                }}
            >
                <div style={{ marginTop: "20px", display: "flex", width: "100%", height: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>

                    {/* <button onClick={exportStageResult} style={{ border: "none", backgroundColor: "transparent" }}>

                        <a className='btn blue'>
                            ดึงข้อมูลด่าน
                        </a>
                    </button>
                    <button onClick={exportPretest} style={{ border: "none", backgroundColor: "transparent" }}>

                        <a className='btn blue'>
                            ดึงข้อมูล Pretest
                        </a>
                    </button>
                    <button onClick={exportPosttest} style={{ border: "none", backgroundColor: "transparent" }}>

                        <a className='btn blue'>
                            ดึงข้อมูล Post Test
                        </a>
                    </button> */}

                    {stageResults ? <JsonToExcel
                        btnClassName="btn orange"
                        title="ดาวน์โหลดคำตอบด่าน"
                        data={stageResults}
                        fileName="stage-results"
                    /> : <Spin />}
                    {pretestAnswers ? <JsonToExcel
                        btnClassName="btn orange"
                        title="ดาวน์โหลดคำตอบ Pretest"
                        data={pretestAnswers}
                        fileName="pretest-answers"
                    /> : <Spin />}
                    {posttestAnswers ? <JsonToExcel
                        btnClassName="btn orange"
                        title="ดาวน์โหลดคำตอบ Posttest"
                        data={posttestAnswers}
                        fileName="posttest-answers"
                    /> : <Spin />}
                    {prePostScores ? <JsonToExcel
                        btnClassName="btn orange"
                        title="ดาวน์โหลดคะแนน Pretest Posttest"
                        data={prePostScores}
                        fileName="pretest-posttest-scores"
                    /> : <Spin />}



                    <Link key="login_btn" to="/login" replace className="btn blue">ไปหน้า Login</Link>
                </div>
            </Form.Item>
            </Form>
        </div>
    );
}

export default ExportTestResultsPage;