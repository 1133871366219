
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import { Pagination } from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/pagination.css";
import "@egjs/react-flicking/dist/flicking.css";
import "../interface.scss";
// import "@egjs/react-flicking/dist/flicking-inline.css";
import React, { useEffect, useState } from 'react'
import { SERVER_URL } from '../Config'
import { useStageStore } from './StageTemplate';

function TutorialPage(props) {
    const _plugins = [new Pagination({ type: 'bullet' })];
    const currentStage = useStageStore(state => state.currentStage)
    const nextStep = useStageStore(state => state.nextStep)
    const prevStep = useStageStore(state => state.prevStep)

    const [readTutorial, setReadTutorial] = useState(false)

    useEffect(() => {
        setReadTutorial(false)
    }, [])
    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>

            <div>

                <Flicking
                    // align="prev"
                    // circular={false}
                    plugins={_plugins}
                    // bound={true}
                    onMoveEnd={e => {
                        console.log(e);
                        setReadTutorial(true);
                    }}>

                    {currentStage.tutorial.map((t, index) => (
                        <div className="panel" key={`tutorial${index}`} style={{ display: "flex", flexDirection: "column", width: "100%", maxWidth: "640px", display: "flex", alignItems: "center", justifyContent: "center", padding: "16px", marginBottom: "20px" }}>
                            <img src={`${SERVER_URL}${t.image.url}`} style={{ width: "85%", maxWidth: "640px", borderRadius: "18px", marginBottom: "20px", pointerEvents: "none" }}></img>
                            {/* <span className="dnaContent" style={{ width: "85%", maxWidth: "640px", textAlign: "center" }}>{t.content}</span> */}
                        </div>

                    ))}
                    <ViewportSlot>
                        <div className="flicking-pagination"></div>
                    </ViewportSlot>
                </Flicking>
            </div>
            <div>
                <a onClick={prevStep} className="btn blue">ย้อนกลับ</a>
                {/* <a onClick={nextStep} className={readTutorial ? "btn orange" : "btn orange disabled"}>เริ่มเล่น</a> */}
                <a onClick={nextStep} className={"btn orange"}>เริ่มเล่น</a>
            </div>
        </div >
    );
}

export default TutorialPage;