import React, { useState, Suspense, useRef, useEffect, useImperativeHandle } from 'react'
import { ARCanvas, Interactive, useXR } from '@react-three/xr'
import { Text, Billboard, Loader, Preload, Image, OrbitControls } from '@react-three/drei'
import { Link, useNavigate } from "react-router-dom";

import {
    AimOutlined
} from '@ant-design/icons';
import * as ANTD from 'antd';
import { Row, Col } from 'antd';
import { SERVER_URL } from '../../Config'
import { Heart, Box } from '../models/Models';
import { useSpring } from 'react-spring';
import { useAppStore } from '../../App';
import { useStageStore } from '../../components/StageTemplate';
import { useThree } from '@react-three/fiber';
import { Group } from 'three';
import { animated } from '@react-spring/three'


function Sign({ ...props }) {
    const [hover, setHover] = useState(false)
    const [color, setColor] = useState('blue')

    const { onClick = () => { }, children = "", bgColor = "white" } = props;
    const onSelect = () => {
        // setColor((Math.random() * 0xffffff) | 0)
        onClick();
    }

    return (
        <>
            <Box color={bgColor} size={[0.6, 0.1, 0.1]} {...props}>
                <React.Suspense fallback={null}>
                    <Text position={[0, 0, 0.06]} fontSize={0.08} color="#000" font="fonts/ttf/fc_condensed.ttf" anchorX="center" anchorY="middle">
                        {children}
                    </Text>
                </React.Suspense>
            </Box>
        </>
    )
}

const ExitARInstruction = () => {
    const { isPresenting } = useXR();
    if (isPresenting) {
        return (
            <Suspense fallback={null}>
                <Billboard
                    position={[0, 0.3, -0.5]}
                    follow={true}
                    lockX={false}
                    lockY={false}
                    lockZ={false} // Lock the rotation on the z axis (default=false)
                >
                    <React.Suspense fallback={null}>
                        <Text
                            color={'#ff2222'}
                            fontSize={0.1}
                            maxWidth={1.6}
                            lineHeight={1.4}
                            letterSpacing={0.02}
                            textAlign={'center'}
                            font="/fonts/ttf/fc_condensed.ttf"
                            anchorX="center"
                            anchorY="middle"
                            outlineWidth={0.005}
                            outlineColor="#ffffff">
                            กรุณากดปุ่มกากบาทที่มุมขวาบนเพื่อออกจากโหมด AR
                        </Text>
                    </React.Suspense>
                    {/* <Text fontSize={0.15} color="gray">จงหา Promoter และ Terminator</Text> */}
                </Billboard>
            </Suspense>
        )
    }
    else
        return null;

}

export function AbstractStage({ gameData = null, children, ingameRefScale = [1, 1, 1], totalQuestionsPerGameStage = 3 }) {
    const textref = useRef();
    const dnaref = useRef();
    const [lives, setLives] = useState(3);
    const [currentGameStage, setCurrentGameStage] = useState();
    const totalQuestions = totalQuestionsPerGameStage;
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(1);
    const [isGameEnded, setIsGameEnded] = useState(false);

    const playSound = useAppStore(state => state.playSound)
    const playBGSound = useAppStore(state => state.playBGSound)
    const stopBGSound = useAppStore(state => state.stopBGSound)
    const nextStep = useStageStore(state => state.nextStep)
    const nextGameStage = useStageStore(state => state.nextGameStage)
    const currentGameStageIndex = useStageStore(state => state.currentGameStageIndex)
    const currentStage = useStageStore(state => state.currentStage)
    const pushGameAttempt = useStageStore(state => state.pushGameAttempt)
    const resetGameAttempts = useStageStore(state => state.resetGameAttempts)
    const setGameResult = useStageStore(state => state.setGameResult)
    const stages = useAppStore(state => state.stages)

    const navigate = useNavigate();

    const orbitref = useRef()

    useEffect(() => {
        resetGameAttempts();
    }, [])

    const onAnswered = (index, result, getNewQuestion = () => { }, doCallNewQuestion = false) => {


        pushGameAttempt(result ? "win" : "lose");

        console.log("currentQuestionIndex " + currentQuestionIndex)
        console.log("totalQuestions " + totalQuestions)
        console.log("children.length " + children().length)
        if (!result) {
            setLives(lives - 1)

            playSound("lose");
            setGameResult(false);
            if (doCallNewQuestion) {
                getNewQuestion();
            }

            return;
        }
        else if (currentGameStageIndex < (children().length - 1)) {
            nextGameStage();
        }
        else if (currentQuestionIndex < totalQuestions) {

            playSound("victory");
            if (doCallNewQuestion)
                getNewQuestion();
            setCurrentQuestionIndex(currentQuestionIndex + 1)
        }
        else {

            setGameResult(true);
            playSound("win");
            setTimeout(() => {
                playSound("complete");
                setIsGameEnded(true);
            }, 500)

        }
    }


    useEffect(() => {

        const stageID = currentStage.id;
        const currentGameSt = (stageID === 6) ? getStageByID(currentGameStageIndex + 1) : currentStage
        setCurrentGameStage(currentGameSt);
    }, [currentGameStageIndex, stages])
    useEffect(() => {

        const stageID = currentStage.id;
        const currentGameSt = (stageID === 6) ? getStageByID(currentGameStageIndex + 1) : currentStage
        setCurrentGameStage(currentGameSt);
        // console.log("useEffect AbstractStage")
        // playBGSound("gameplay");
        return () => {
            console.log("unmount stage")

            // stopBGSound();
        }
    }, [])


    useEffect(() => {
        if (lives <= 0) {
            setTimeout(() => {
                playSound("gameover");
                navigate("../gameover", { replace: true });
            }, 500)
        }
    }, [lives])

    const gameOver = () => {
        navigate("../menu", { replace: true });
    }

    const getStageByID = (id) => {
        const stageData = stages.find((stDat) => (`${stDat.id}` === `${id}`))

        console.log("stageData " + JSON.stringify(stageData))
        return stageData;
    }
    const InGameRef = () => {

        const { width } = useThree((state) => state.viewport)
        return (
            <Billboard
                position={[1.6, 0.9, -1.2]}
                follow={false}
                lockX={false}
                lockY={false}
                lockZ={false} // Lock the rotation on the z axis (default=false)
            >
                <Image url={`${SERVER_URL}${currentGameStage.ingame_ref.url}`} scale={ingameRefScale} transparent={false} opacity={1} />
            </Billboard>
        )
    }

    if (!currentGameStage)
        return null
    return (
        <>
            <div style={{ position: "absolute", lleft: "20px", top: "5px" }}>
                <Link to="/menu" className="btn grey" style={{ fontSize: "18px" }}>กลับสู่เมนู</Link>
            </div>
            <div style={{ width: "100%", height: "75px", backgroundColor: "rgba(0,0,0,0.75)", color: "white", fontSize: "28px", fontWeight: 600, display: "flex", alignItems: "center", justifyContent: "center" }}><ANTD.Button type="link" size='large' style={{ fontWeight: 600, color: "white", fontSize: "20px" }}></ANTD.Button>{currentStage.name}</div>
            {(isGameEnded) ? <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", zIndex: 10000 }}><button onClick={() => { nextStep(); }} type="submit" style={{ border: "none", backgroundColor: "transparent" }}>
                <a className='btn blue'>
                    ไปตอบคำถามท้ายเกมกัน
                </a>
            </button></div> : null}
            <div style={{ width: "100%", height: "calc(100% - 50px)" }}>
                <ARCanvas performance={{ min: 0.5 }} frameloop="demand" dpr={[1, 2]} camera={{ position: [0, 0, 2], fov: 50 }}>
                    <Preload all />
                    <group position={[0, 0, -2]}>
                        {(isGameEnded) ? (<ExitARInstruction></ExitARInstruction>) : <Suspense fallback={null}>
                            <ambientLight />

                            <Heart position={[-1.5, 1, -1]} color={lives >= 1 ? "red" : "grey"}></Heart>
                            <Heart position={[-1.3, 1, -1]} color={lives >= 2 ? "red" : "grey"}></Heart>
                            <Heart position={[-1.1, 1, -1]} color={lives >= 3 ? "red" : "grey"}></Heart>

                            <pointLight position={[10, 10, 10]} />


                            <InGameRef></InGameRef>
                            <Billboard
                                position={[0, 0.6, -0.5]}
                                follow={false}
                                lockX={false}
                                lockY={false}
                                lockZ={false} // Lock the rotation on the z axis (default=false)
                            >
                                <React.Suspense fallback={null}>
                                    <Text
                                        ref={textref}
                                        color={'#ff2222'}
                                        fontSize={0.1}
                                        maxWidth={1.6}
                                        lineHeight={1.4}
                                        letterSpacing={0.02}
                                        textAlign={'center'}
                                        font="/fonts/ttf/fc_condensed.ttf"
                                        anchorX="center"
                                        anchorY="middle"
                                        outlineWidth={0.005}
                                        outlineColor="#ffffff">
                                        {currentGameStage.ingame_instruction}
                                    </Text>
                                </React.Suspense>
                                {/* <Text fontSize={0.15} color="gray">จงหา Promoter และ Terminator</Text> */}
                            </Billboard>
                            <Billboard
                                position={[0, 0.9, -0.5]}
                                follow={false}
                                lockX={false}
                                lockY={false}
                                lockZ={false} // Lock the rotation on the z axis (default=false)
                            >
                                <React.Suspense fallback={null}>
                                    <Text
                                        ref={textref}
                                        color={'#222222'}
                                        fontSize={0.14}
                                        maxWidth={1.5}
                                        lineHeight={1.5}
                                        letterSpacing={0.02}
                                        textAlign={'center'}
                                        font="/fonts/ttf/fc_condensed.ttf"
                                        anchorX="center"
                                        anchorY="middle"
                                        outlineWidth={0.005}
                                        outlineColor="#ffffff">
                                        {`โจทย์ข้อที่ ${currentQuestionIndex + currentGameStageIndex}/${totalQuestions * children().length}`}
                                    </Text>
                                </React.Suspense>
                                {/* <Text fontSize={0.15} color="gray">จงหา Promoter และ Terminator</Text> */}
                            </Billboard>
                            {children(currentStage, onAnswered)[currentGameStageIndex]}
                            <OrbitControls ref={orbitref}></OrbitControls>

                        </Suspense >}

                    </group>

                </ARCanvas >
                <div style={{ position: "absolute", left: 0, top: 0, width: "100%", height: "100%", pointerEvents: "none", display: "flex", justifyContent: "center" }}>
                    {/* <Row style={{ width: "100%" }}>
                        <Col xs={0} md={0} lg={0} xl={0}>
                            <button onClick={() => {
                                if (orbitref && orbitref.current) {
                                    orbitref.current.reset();
                                }
                            }} style={{ position: "absolute", bottom: 8, right: 8, border: "none", backgroundColor: "transparent", pointerEvents: "auto", transform: "scale(0.75)" }}>

                                <a className='btn orange'>
                                    <AimOutlined style={{ marginLeft: "-5px", marginRight: "5px" }} />รีเซตกล้อง
                                </a>
                            </button></Col>
                        <Col xs={24} md={24} lg={24} xl={24} style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center" }}> */}

                    <button onClick={() => {
                        if (orbitref && orbitref.current) {
                            orbitref.current.reset();
                        }
                    }} style={{ position: "absolute", bottom: 52, border: "none", backgroundColor: "transparent", pointerEvents: "auto", transform: "scale(0.75)" }}>

                        <a className='btn orange'>
                            {/* รีเซตตำแหน่ง */}
                            <AimOutlined style={{ marginLeft: "-5px", marginRight: "5px" }} />รีเซตมุมกล้อง
                        </a>
                    </button>
                    {/* </Col>
                    </Row> */}
                </div>

                <Loader />
            </div>

        </>
    )
}
