import React from 'react';
import { SERVER_URL } from '../Config';
import { useStageStore } from './StageTemplate';
import "../interface.scss";
import { Date, Link, RichText } from 'prismic-reactjs';
import ReactMarkdown from "react-markdown";

function PreKnowledgePage() {

    const currentStage = useStageStore(state => state.currentStage)
    const nextStep = useStageStore(state => state.nextStep)
    const prevStep = useStageStore(state => state.prevStep)

    const { knowledge } = currentStage;

    if (!currentStage)
        return null

    return (
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", height: "100%" }}>
            {currentStage.cover_image ? <img src={SERVER_URL + knowledge.image.url} style={{ width: "80%", maxWidth: "500px", height: "auto", maxHeight: "500px", objectFit: "contain", margin: "20px", borderRadius: "20px", pointerEvents: "none", userSelect: "none" }}></img> : null}
            {/* <span className="dnaHeader" style={{ textAlign: "center" }}>{currentStage.name}</span> */}

            <div style={{ width: "85%", maxWidth: "640px", height: "auto", fontSize: "16px", maxHeight: "300px", overflow: "scroll", fontFamily: "Kanit", overflowX: "hidden", backgroundColor: "white", padding: "10px", marginBottom: "20px", userSelect: "none" }}>

                <ReactMarkdown children={knowledge.formatted_content} escapeHtml={false} />
            </div>
            <div>
                <a onClick={prevStep} className="btn blue">ย้อนกลับ</a>
                <a onClick={nextStep} className="btn orange">ไปต่อ</a>
            </div>
        </div >
    );
}

export default PreKnowledgePage;