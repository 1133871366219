import React, { Component, useEffect, useState, useRef } from 'react';

import { useAppStore } from '../../App';
import PretestQuestionModule from '../../components/PretestQuestionModule';
import { Row, Col, Steps, Divider, Spin, Space, Result, Button } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { Link, useParams } from "react-router-dom";
import { PretesProvider } from '../../context/PretestContext';
import { authPostRequest } from '../../utils/server';
import { useAuth } from '../../context/AuthContext';


const { Step } = Steps;

function Pretest(props) {

    const pretestData = useAppStore(state => state.pretestData);
    const currentPretestIndex = useAppStore(state => state.currentPretestIndex);
    const pretestAnswers = useAppStore(state => state.pretestAnswers);
    const updatePretestResult = useAppStore(state => state.updatePretestResult);
    const nextPretest = useAppStore(state => state.nextPretest);
    const resetPretest = useAppStore(state => state.resetPretest);
    const [showRedo, setShowRedo] = useState(true);

    let { isposttest = "false" } = useParams();
    const { user } = useAuth();
    var errSum = 0;

    var lastTotalAnswers = useRef(0);

    const onChange = (value) => {
        // console.log('onChange:', current);
        // setCurrent(value);
    };

    useEffect(() => {
        if (currentPretestIndex > 0) {
            setShowRedo(false)
        }
    }, [currentPretestIndex])



    useEffect(() => {
        setShowRedo(true)
        console.log("useEffectssss")
        lastTotalAnswers.current = 0;
        resetPretest();
    }, [])

    useEffect(() => {
    }, [pretestData])

    const preparePretestResults = () => {
        const { isPostTest = false } = props;
        var sumWeights = [0, 0, 0, 0, 0];
        var resultObj = {}
        pretestData.forEach((data, index) => {
            const answer = pretestAnswers[index];
            const { lp_weight } = data

            resultObj[`pretest${index + 1}_answer`] = answer.answerChoiceID;
            resultObj[`pretest${index + 1}_result`] = answer.result;

            console.log("sumWeights " + JSON.stringify(sumWeights))
            console.log("lp_weight " + JSON.stringify(lp_weight.map((w) => w.weight)))
            if (lp_weight) {
                if (!answer.result) {
                    sumWeights = sumWeights.map((oldweight, index2) => {

                        console.log("oldweight " + oldweight)
                        console.log("index " + index)
                        console.log("sumWeights " + JSON.stringify(sumWeights))
                        return oldweight + lp_weight[index2].weight;
                    })
                }
            }
        })

        errSum = sumWeights.reduce((prev, cur) => prev + cur, 0)
        const lpArray = sumWeights.map(weight => {
            var lpObj = {}

            lpObj.sum_weight = weight;

            const low = calculateLOW(weight);
            const avg = calculateAVG(weight);
            const high = calculateHIGH(weight);
            const lp = calculatLP(low, avg, high)
            lpObj.LOW = low;
            lpObj.AVG = avg;
            lpObj.HIGH = high;
            lpObj.learning_performance = lp
            return lpObj
        })

        const lpStageObj = {};
        const stagesToPlay = [];
        lpArray.forEach((lpResult, index) => {
            lpStageObj[`stage${index + 1}`] = lpResult;
            if (lpResult.learning_performance !== "well_learned") {
                stagesToPlay.push(`stage${index + 1}`);
            }
        })

        resultObj.learning_performance = lpStageObj
        resultObj.stages_to_play = JSON.stringify(stagesToPlay)
        console.log("sumWeights " + sumWeights)

        resultObj["is_post_test"] = (isposttest === "true") ? true : false;
        return resultObj;
    }

    const calculatLP = (low, avg, high) => {
        const maxValue = Math.max(low, avg, high)
        var result = "";

        console.log("maxValue " + maxValue)
        console.log("low " + low)
        console.log("avg " + avg)
        console.log("high " + high)
        switch (maxValue) {
            case low:
                result = 'well_learned';
                break
            case avg:
                result = 'partial_learned';
                break
            case high:
                result = 'poorly_learned';
                break
        }

        return result;
    }

    const calculateLOW = (weight) => {
        const err_cj = weight / errSum
        var result = 0;

        console.log("low ")
        console.log("weight " + weight)
        console.log("err_cj " + err_cj)
        if (err_cj === 0) {
            console.log("l1")
            result = 1
        }
        else if (err_cj > 0 && err_cj < 0.5) {
            console.log("l2")
            result = 1 - (2 * (err_cj * err_cj));
        }
        else if (err_cj >= 0.5 && err_cj < 1) {
            console.log("l3")
            result = 2 * ((err_cj - 1) * (err_cj - 1));
        }
        else if (err_cj === 1) {
            console.log("l4")
            result = 0
        }

        return Math.floor(result * 100) / 100;
    }


    const calculateAVG = (weight) => {
        const err_cj = weight / errSum
        var result = 0;

        if (err_cj === 0) {
            result = 0
        }
        else if (err_cj > 0 && err_cj < 0.25) {
            result = 2 * ((err_cj / 0.5) * (err_cj / 0.5));
        }
        else if (err_cj >= 0.25 && err_cj < 0.5) {
            result = 1 - 2 * (((err_cj - 0 - .5) / 0.5) * ((err_cj - 0 - .5) / 0.5));
        }
        else if (err_cj >= 0.5 && err_cj < 0.75) {
            result = 1 - 2 * (((err_cj - 0 - .5) / 0.5) * ((err_cj - 0 - .5) / 0.5));
        }
        else if (err_cj >= 0.75 && err_cj < 1) {
            result = 2 * (((err_cj - 1) / 0.5) * ((err_cj - 1) / 0.5));
        }
        else if (err_cj === 1) {
            result = 0
        }

        return Math.floor(result * 100) / 100;
    }


    const calculateHIGH = (weight) => {
        const err_cj = weight / errSum
        var result = 0;

        if (err_cj === 0) {
            result = 0
        }
        else if (err_cj > 0 && err_cj < 0.5) {
            result = 2 * (err_cj * err_cj);
        }
        else if (err_cj >= 0.5 && err_cj < 1) {
            result = 1 - (2 * ((err_cj - 1) * (err_cj - 1)));
        }
        else if (err_cj === 1) {
            result = 1
        }

        return Math.floor(result * 100) / 100;
    }

    const onFinishedPretest = async () => {

        const resultJSON = preparePretestResults();

        console.log("onFinishedPretest " + JSON.stringify(resultJSON))

        updatePretestResult(resultJSON);
        await authPostRequest("pretest-answers", resultJSON, user.jwt)
    }

    useEffect((prev) => {
        // console.log("prev pretestAnswers " + JSON.stringify(prev))
        // console.log("pretestAnswers " + JSON.stringify(pretestAnswers))
        // console.log("pretestData " + JSON.stringify(pretestData))
        console.log("11 " + lastTotalAnswers.current)
        console.log("11 " + pretestAnswers.length)
        if (lastTotalAnswers.current === (pretestData.length - 1) && pretestAnswers.length === pretestData.length) {
            console.log("22")

            onFinishedPretest();
        }
        console.log("pretestAnswers " + pretestAnswers.length)
        lastTotalAnswers.current = pretestAnswers.length
        console.log("lastTotalAnswers " + lastTotalAnswers.current)
    }, [pretestAnswers])


    if (!(pretestData && pretestData.length))
        return (<div style={{ width: "100%", height: "100%", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }} >
            <Spin size='large' />
        </ div>)

    //https://forms.gle/so4zrNUSY9jp2AUJ7

    const actionButton = (isposttest === "true") ? <a className="btn orange" target="_blank" href="https://forms.gle/so4zrNUSY9jp2AUJ7">ร่วมทำแบบสอบถาม</a> : null;
    const menuButton = (isposttest === "true" && false) ? null : <Link to="/menu"><a className="btn blue">กลับไปยังเมนูหลัก</a></Link>;
    // const actionButton = (isposttest === "true") ? <a className="btn orange" target="_blank" href="https://forms.gle/so4zrNUSY9jp2AUJ7">ร่วมทำแบบสอบถาม</a> : <Link to="/menu"><a className="btn blue">กลับไปยังเมนูหลัก</a></Link>
    if (pretestAnswers.length == pretestData.length && (!showRedo || !(isposttest === "true"))) {
        return (<div style={{ width: "100%", height: "100%", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }} >
            <Result
                icon={<SmileOutlined />}
                title={(isposttest === "true") ? "ขอบคุณที่ร่วมสนุกกันนะคะ" : "คุณได้ตอบคำถามพรีเทสต์ครบแล้ว!"}
                extra={<>{showRedo ? <a className="btn orange" onClick={() => { resetPretest() }}>ทำใหม่อีกครั้ง</a> : null}{actionButton}{(isposttest === "true") ? null : menuButton}</>}
            // extra={<>{showRedo ? <a className="btn orange" onClick={() => { resetPretest() }}>ทำใหม่อีกครั้ง</a> : null}{actionButton}{menuButton}</>}
            />
        </ div >
        )
    }
    return (
        <Row gutter={16} style={{ padding: "20px", backgroundColor: "rgba(0, 0, 0, 0.5)", background: "url('./images/dna_background.png')", minHeight: "100%", fontFamily: "Kanit", fontWeight: 400, alignContent: "flex-start" }} justify="center" >
            <Col span={24} style={{ margin: "0px", width: "100%", textAlign: "center", fontWeight: 800 }}>

                <h1 style={{ fontSize: "30px", fontWeight: 600 }}>{(isposttest === "true") ? "คำถาม POSTTEST" : "คำถาม PRETEST"}</h1>
                <Divider></Divider>
            </Col>
            <Col xs={0} sm={0} md={4} lg={3} xl={3} xxl={2} style={{ marginTop: "20px" }}>
                <Steps key="steps_v" current={currentPretestIndex} onChange={onChange} responsive="false" direction="vertical">
                    {
                        pretestData.map((test, index) => {
                            const title = index + 1;

                            let status = "wait"

                            if (index === currentPretestIndex)
                                status = "processing"
                            else if (pretestAnswers.length > index) {
                                status = "finish"
                                // status = (pretestAnswers[index].result ? "finish" : "error");
                                // console.log("pretestAnswers[index].result " + pretestAnswers[index].result)
                            }


                            return <Step key={`step_${index}`} title={"ข้อ " + title} status={status} />
                        })
                    }
                </Steps>
            </Col>
            <Col xs={3} sm={3} md={0} lg={0} xl={0} xxl={0} >
                <Steps key="steps_v" current={currentPretestIndex} onChange={onChange} responsive="false" direction="vertical">
                    {
                        pretestData.map((test, index) => {
                            const title = index + 1;
                            let status = "wait"

                            if (index === currentPretestIndex)
                                status = "processing"
                            else if (pretestAnswers.length > index) {
                                status = "finish"
                                // status = (pretestAnswers[index].result ? "finish" : "error");
                                // console.log("pretestAnswers[index].result " + pretestAnswers[index].result)
                            }
                            return <Step key={`set_${index}`} title={""} status={status} />
                        })
                    }
                </Steps>
            </Col>
            <Col xs={21} sm={15} md={15} lg={15} xl={14} xxl={12}>
                <PretestQuestionModule pretestQuestionData={pretestData[currentPretestIndex]}></PretestQuestionModule>
            </Col>
        </Row>
    );
}

export default Pretest;