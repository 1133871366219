import React, { Suspense, useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, message, Modal } from 'antd';
import "../../interface.scss";
import { Link, useNavigate } from 'react-router-dom';
import { Canvas, ambientLight } from '@react-three/fiber';
import { Box, OrbitControls, Float, Stage, Center, ContactShadows } from '@react-three/drei'
import DNAString from '../models/Dna_string';
import { useAuth } from '../../context/AuthContext';



function Register(props) {
    const [isLoading, setIsLoading] = useState(false);
    const { signup } = useAuth();
    const navigate = useNavigate();
    const onFinish = async (values) => {
        console.log('Success:', values);

        const { username, email, password, confirm_password } = values

        if (password !== confirm_password) {
            Modal.error({ title: "ยืนยันรหัสผ่านไม่ถูกต้อง กรุณาตรวจสอบใหม่อีกครั้ง" })
        }
        else {
            const resultObj = await signup(username, email, password);

            console.log("0 ")
            if (resultObj.message && resultObj.message.length) {
                console.log("1")
                resultObj.message.forEach(element => {
                    console.log("2")
                    if (element.messages && element.messages.length) {

                        Modal.error({ title: element.messages[0].message })
                        // console.log("3")
                        // element.messages.forEach(element2 => {
                        //     console.log("4" + element2.message)
                        //     Modal.error({ title: element2.message })
                        // })
                    }
                });
            }
            else {

                setIsLoading(true);
                message.success('ลงทะเบียนสำเร็จแล้ว กำลังไปหน้า Login', 1.5).then(() => {
                    setIsLoading(false);
                    navigate("../login", { replace: true });
                })
            }

            console.log("response " + JSON.stringify(resultObj))
        }
        // if()
        // signup(username, email, password, news_subscription = true)
    };

    useEffect(() => {
        setIsLoading(false);
    }, [])
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%" }}>
            <div style={{ display: "inline-block", width: "600px", maxWidth: "85%", marginTop: "50px" }}>
                <div style={{ display: "inline-block", width: "100%", height: "200px", display: "flex", alignItems: "center", justifyContent: "center" }}>


                    <Suspense fallback={null}>

                        <Canvas pixelRatio={1}>
                            <ambientLight />
                            <Center>
                                <Float
                                    speed={3} // Animation speed, defaults to 1
                                    rotationIntensity={1} // XYZ rotation intensity, defaults to 1
                                    floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
                                    floatingRange={[1, 10]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
                                >
                                    <DNAString scale={6}></DNAString>
                                </Float>
                            </Center>
                            <OrbitControls></OrbitControls>
                        </Canvas>
                    </Suspense>
                </div>
                <div className='dnaHeader' style={{ marginBottom: "20px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    ลงทะเบียน
                </div>
                <Form
                    disabled={isLoading}
                    style={{ fontFamily: "Kanit", width: "100%" }}
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="ชื่อผู้เล่น"
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณาระบุชื่อผู้เล่น!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="อีเมล"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณาใส่อีเมล!',
                            },
                            {
                                type: "email",
                                message: "อีเมลไม่ถูกต้อง",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="รหัสผ่าน"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณาใส่รหัสผ่าน!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="ยืนยันรหัสผ่าน"
                        name="confirm_password"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณาใส่รหัสผ่านอีกครั้ง!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    {/* <Form.Item
                    name="remember"
                    valuePropName="checked"
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Checkbox>จำรหัสผ่าน</Checkbox>
                </Form.Item> */}

                    <Form.Item
                        wrapperCol={{
                            offset: 5,
                            span: 19,
                        }}
                    >
                        <div style={{ marginTop: "20px" }}>
                            <Link key="register_btn" to="/login" replace className="btn orange">
                                เคยลงทะเบียนแล้ว
                            </Link>
                            <button type="submit" style={{ border: "none", backgroundColor: "transparent" }} >
                                <a className='btn blue' >
                                    ลงทะเบียน
                                </a>
                            </button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </div>

    );
}

export default Register;