import React from 'react';
import { SERVER_URL } from '../Config';
import { useStageStore } from './StageTemplate';
import "../interface.scss";
import { Link } from 'react-router-dom';


function GameOverPage() {

    const currentStage = useStageStore(state => state.currentStage)
    const nextStep = useStageStore(state => state.nextStep)
    const prevStep = useStageStore(state => state.prevStep)

    if (!currentStage)
        return null

    return (
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", height: "100%" }}>
            <span className="dnaHeader" style={{ textAlign: "center" }}>GAME OVER</span>
            <div>
                <Link to="/menu"><a className="btn blue" style={{ fontSize: "18px" }}>กลับสู่เมนู</a></Link>
            </div>
        </div>
    );
}

export default GameOverPage;