import React, { Suspense, useEffect } from 'react';
import { Button, Checkbox, Form, Input, message } from 'antd';
import "../../interface.scss";
import { Link, useNavigate } from 'react-router-dom';
import { Canvas, ambientLight } from '@react-three/fiber';
import { Box, OrbitControls, Float, Stage, Center, ContactShadows } from '@react-three/drei'
import DNAString from '../models/Dna_string';
import { useAuth } from '../../context/AuthContext';
import { useAppStore } from '../../App';

function Login(props) {
    const { login, user } = useAuth();
    const navigate = useNavigate();
    const stopBGSound = useAppStore(state => state.stopBGSound)
    const onFinish = async (values) => {
        console.log('Success:', values);
        const { email, password } = values;

        const result = await login(email, password);
        console.log("result " + JSON.stringify(result));

        if (result.error) {
            message.error("อีเมลหรือรหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง")
        }
    };

    useEffect(() => {
        stopBGSound();
    }, [])

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
            <div style={{ display: "inline-block", width: "85%", maxWidth: "85%", marginTop: "0px" }}>

                <div style={{ display: "inline-block", width: "100%", height: "180px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Suspense fallback={null}>

                        <Canvas>
                            <ambientLight />
                            <Center>
                                <Float
                                    speed={2} // Animation speed, defaults to 1
                                    rotationIntensity={1} // XYZ rotation intensity, defaults to 1
                                    floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
                                    floatingRange={[1, 10]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
                                >
                                    <DNAString scale={5}></DNAString>
                                </Float>
                            </Center>
                            <OrbitControls></OrbitControls>
                        </Canvas>
                    </Suspense>
                </div>
                <div className='dnaHeader' style={{ marginBottom: "20px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {user ? `คุณ ${user.user.username}` : "ลงชื่อเข้าเกม"}
                </div>
                <div className='dnaSubHeader' style={{ marginBottom: "20px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {user ? `ถ้าพร้อมแล้วก็กดเริ่มกันเลย` : ""}
                </div>

                {
                    user ? (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <button onClick={() => {
                            navigate("../menu", { replace: true });
                        }} style={{ border: "none", backgroundColor: "transparent" }}>

                            <a className='btn blue'>
                                เริ่มเกมกันเถอะ
                            </a>
                        </button></div>) : (<Form
                            style={{ fontFamily: "Kanit" }}
                            name="basic"
                            labelCol={{
                                span: 6,
                            }}
                            wrapperCol={{
                                span: 14,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="อีเมล"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'กรุณาใส่อีเมล!',
                                    },
                                    {
                                        type: "email",
                                        message: "อีเมลไม่ถูกต้อง",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="รหัสผ่าน"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'กรุณาใส่รหัสผ่าน!',
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>

                            {/* <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <Checkbox>จำรหัสผ่าน</Checkbox>
            </Form.Item> */}

                            <Form.Item
                                wrapperCol={{
                                    span: 24,
                                }}
                            >
                                <div style={{ marginTop: "20px", display: "flex", width: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>

                                    <button type="submit" style={{ border: "none", backgroundColor: "transparent" }}>

                                        <a className='btn blue'>
                                            เข้าสู่เกม
                                        </a>
                                    </button>
                                    <Link key="register_btn" to="/register" replace className="btn orange">ยังไม่เคยลงทะเบียน</Link>
                                </div>
                            </Form.Item>
                        </Form>)
                }

            </div>
        </div >

    );
}

export default Login;