import React, { useState, Suspense, useRef, useEffect, useImperativeHandle } from 'react'
import { DefaultXRControllers, ARCanvas, useXREvent, Interactive, canva } from '@react-three/xr'
import { Text, useGLTF, Html, Billboard, OrbitControls, Environment, Float, Loader, Preload, ContactShadows, Image, Center, Bounds, useBounds, Box } from '@react-three/drei'
import { useAppStore } from '../../App'
import { useSpring, animated } from '@react-spring/three'
import { DNABase, DNAGameString, DNAPair, getCounterBaseType } from '../models/DNAGameModels'

// import Stats from 'stats.js';
import { Button, Checkbox, Slider } from 'antd';

// function MyUseFrame(props) {

//   const stats = new Stats()


//   document.body.appendChild(stats.dom)
//   useFrame(() => {
//     stats.begin();
//     stats.end();
//   })

//   stats.showPanel(0);

//   return null;
// }
export function TestStage(props) {
  const textref = useRef();
  const dnaref = useRef();
  const playSound = useAppStore(state => state.playSound)

  const { gameData = null, onAnswered } = props;
  const [qSequence, setQSequence] = useState(null)
  const [clearDNA, setClearDNA] = useState(false)
  const [correctCount, setCorrectCount] = useState(0)
  const [doSpin, setDoSpin] = useState(false)
  const [topVisible, setTopVisible] = useState(true)
  const [bottomVisible, setBottomVisible] = useState(true)

  const [nonSpinRange, setNonSpinRange] = useState([0, 0])
  const [splitOnNonSpin, setSplitOnNonSpin] = useState(false)
  const [rotationStep, setRotationStep] = useState(0);
  const [toHideTop, setToHideTop] = useState([])
  const [toHideBottom, setToHideBottom] = useState([])
  const [toHidePair, setToHidePair] = useState([3, 4, 5, 6])
  const [toHideGroup, setToHideGroup] = useState([])


  // useEffect(() => {
  //   bounds.refresh().clip().fit()
  // }, [bounds])

  const dnaProps = useSpring({ scale: clearDNA ? [0, 0, 0] : [1, 1, 1], position: [0, -0.1, -0.5] })


  // const mArray = ["a", "a", "t", "c", "g", "g", "c", "c", "a", "g", "g", "g", "c", "c", "a", "g", "a", "g", "t", "c", "a"]
  const mArray = ["a", "a", "t", "c", "g", "g", "c", "c", "a", "g", "g", "g", "c", "c", "a", "g", "a", "g", "t", "c", "a"]
  const onChange = (value) => {
    setNonSpinRange(value)
    setToHidePair([5, 6])
  };



  return (
    <div style={{ padding: "30px", height: "calc(100% - 60px)" }}>
      {/* <DNA ref={dnaref}  {...dnaProps} sequence={qSequence} onCorrect={onCorrect} onWrong={onWrong} />
      <Interactive onSelect={() => {
        if (dnaref && dnaref.current) {

          dnaref.current.resetSelections();
        }
      }}>
        <Button title={"ทำการเลือกใหม่"} onClick={() => {
          if (dnaref && dnaref.current) {
            dnaref.current.resetSelections();
          }


        }} bgColor="orange" position={[0, -0.5, -0.3
        ]} />
      </Interactive> */}
      <h3>ปริมาณการบิดของเกรียว</h3>
      <Slider
        step={0.1} max={20} onAfterChange={(value) => { setRotationStep(value) }} />
      <h3>ช่วงคลายเกรียว</h3>
      <Slider
        range
        step={1}
        min={-8}
        max={26}
        onAfterChange={onChange}
      />
      <Checkbox size="large" value={topVisible} onChage={(value) => {
        setBottomVisible(value)
      }}>แสดงสายบน</Checkbox>
      <Checkbox size="large">แสดงแค่สายล่าง</Checkbox>
      {/* <Button onClick={() => { setDoSpin(!doSpin) }}>SPIN</Button> */}

      <ARCanvas shadows dpr={[1, 2]} camera={{ position: [0, 0, 2], fov: 40 }}>

        {/* <MyUseFrame></MyUseFrame> */}
        <Preload all />
        <ambientLight />
        <Suspense fallback={null}>

          {/* <Center> */}
          {/* <Box></Box> */}

          {/* <DNAGameString mArray={mArray} rotationStep={rotationStep} nonSpinRange={nonSpinRange} toHideTop={toHideTop} toHideBottom={toHideBottom} toHidePair={toHidePair} toHideGRoup={toHideGroup} intronGroups={[3, 4, 4, 3, 4, 3]}></DNAGameString> */}
          <DNAGameString mArray={mArray} rotationStep={rotationStep} nonSpinRange={nonSpinRange} toHideTop={toHideTop} toHideBottom={toHideBottom} toHidePair={toHidePair} toHideGRoup={toHideGroup} ></DNAGameString>
          {/* </Center> */}
          <OrbitControls></OrbitControls>

        </Suspense>
      </ARCanvas >
    </div >
  )
}
