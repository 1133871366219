import React, { useState, Suspense, useRef, useEffect, useImperativeHandle } from 'react'
import * as THREE from "three"
import create from "zustand"
import { DefaultXRControllers, ARCanvas, useXREvent, Interactive, canva } from '@react-three/xr'
import { Text, useGLTF, Html, Billboard, OrbitControls, Environment, Float, Loader, Preload, ContactShadows, Image } from '@react-three/drei'
import { useFrame, useLoader, Canvas } from "@react-three/fiber"
import { useAppStore } from '../../App'
import { useSpring, animated } from '@react-spring/three'
import { PlaneBufferGeometry } from 'three'
import { Mesh } from 'three'
import { Button } from '../models/Models';
import { DNAGameString } from '../models/DNAGameModels'




const getConplementaryDNANode = (startingLetter) => {
    switch (startingLetter) {
        case "a":
            return "t";
            break;
        case "t":
        case "u":
            return "a";
            break;
        case "c":
            return "g";
            break;
        case "g":
            return "c";
            break;
    }
}



export function Stage4(props) {
    const textref = useRef();
    const dnaref = useRef();
    const playSound = useAppStore(state => state.playSound)

    const { gameData = null, onAnswered } = props;
    const [qSequence, setQSequence] = useState(null)
    const [qGroupSequence, setQGroupSequence] = useState(null)
    const [counterQSequence, setCounterQSequence] = useState(null)
    const [totalVisibleCounter, setTotalVisibleCounter] = useState(0);
    const [clearDNA, setClearDNA] = useState(false)
    const [correctCount, setCorrectCount] = useState(0)


    const [nonSpinRange, setNonSpinRange] = useState([0, 0])
    const [splitOnNonSpin, setSplitOnNonSpin] = useState(false)
    const [rotationStep, setRotationStep] = useState(0);
    const [toHideTop, setToHideTop] = useState([])
    const [toHideBottom, setToHideBottom] = useState([])
    const [toHidePair, setToHidePair] = useState([])
    const [toHideGroup, setToHideGroup] = useState([])

    const dnaProps = useSpring({ scale: clearDNA ? [0, 0, 0] : [1, 1, 1], position: [0, -0.1, -0.5] })
    var questionsSet = [
        ["a", "a", "t", "c", "g", "g", "c", "c", "a", "g", "g", "g", "c", "c", "a", "g", "a", "g", "t", "c", "a"],
        ["c", "u", "t", "g", "a", "c", "u", "a", "g", "u", "c", "a", "g", "u", "c", "a", "c", "g", "a", "g", "a"],
        ["g", "u", "c", "g", "a", "c", "u", "a", "g", "u", "c", "a", "g", "u", "c", "a", "c", "g", "a", "g", "a"]
    ]
    var groupQuestionsSet = [
        [3, 4, 2, 3, 3, 3, 3],
        [3, 3, 3, 5, 3, 4],
        [3, 5, 4, 3, 3, 3],
        // ["a", "t", "g", "g", "a", "c", "u", "a", "g", "u", "c", "a", "g", "u", "c", "a", "c", "g", "a"],
        // ["c", "u", "t", "g", "a", "c", "u", "a", "g", "u", "c", "a", "g", "u", "c", "a", "c", "g", "a"],
        // ["g", "u", "c", "g", "a", "c", "u", "a", "g", "u", "c", "a", "g", "u", "c", "a", "c", "g", "a"]
    ]

    const getCounterSequence = (sequence) => {
        const counter = sequence.map((t) => {
            return getConplementaryDNANode(t)
        })

        setCounterQSequence(counter)
    }
    const getNewQuestion = async () => {

        const rand = Math.min(questionsSet.length - 1, Math.round(questionsSet.length * Math.random()))
        console.log("rand " + rand)
        const newQ = questionsSet[rand]
        const newQGroup = groupQuestionsSet[rand]

        console.log("newQ" + newQ)
        console.log("newQGroup" + newQGroup)
        setQSequence(newQ)
        setQGroupSequence(newQGroup)
        setToHideGroup([]);

        // getCounterSequence(newQ)
        // setTotalVisibleCounter(0)
        // setClearDNA(true)
        // if (dnaref.current) {
        //     dnaref.current.resetSelections();
        // }
        // setTimeout(() => {
        //     setClearDNA(false)
        // }, 1000)
        // console.log("getNewQuestion correctCount " + correctCount)
        setCorrectCount(0)
    }

    useEffect(() => {
        getNewQuestion()

        console.log("useeffect correctCount " + correctCount)
        setCorrectCount(0)
    }, [])

    useEffect(() => {
        if (!qSequence || !qGroupSequence)
            return
        if (totalVisibleCounter === qSequence.length) {
            onAnswered(1, true, getNewQuestion, true)
        }
    }, [totalVisibleCounter])


    useEffect(() => {
        if (!qGroupSequence)
            return
        const totalIntrons = Math.floor(qGroupSequence.length / 2)
        if (correctCount >= totalIntrons) {

            onAnswered(4, true, getNewQuestion, true)
        }
    }, [correctCount])

    if (!qSequence || !qGroupSequence) {
        return null;
    }

    const onCorrect = () => {
        setCorrectCount(correctCount + 1)
        playSound("win");
        console.log("correctCount " + correctCount)

    }


    const onWrong = () => {
        onAnswered(4, false, getNewQuestion, false)
    }

    const onGroupSelected = (boxIndex, isIntron) => {

        handleAnswer(boxIndex, isIntron)
    }

    const handleAnswer = (boxIndex, isIntron) => {

        console.log("handleGroupClicked " + boxIndex + " " + isIntron)

        if (isIntron && !toHideGroup.includes(boxIndex)) {
            setToHideGroup([...toHideGroup, boxIndex])
            onCorrect()
        }
        else if (!isIntron) {
            onWrong();
        }
        console.log("toHideGroup " + toHideGroup)
    }

    const alltypes = ["a", "t", "c", "g"]
    return (
        <>
            <Suspense fallback={null}>

                {/* <Center> */}

                <DNAGameString mArray={qSequence} isRNA={true} topVisible={false} intronGroups={qGroupSequence} rotationStep={rotationStep} onGroupClicked={onGroupSelected} nonSpinRange={nonSpinRange} toHideTop={toHideTop} toHideBottom={toHideBottom} toHidePair={toHidePair} toHideGroup={toHideGroup}></DNAGameString>
                {/* </Center> */}
                <OrbitControls></OrbitControls>

            </Suspense>
            <Interactive onSelect={() => {
                if (dnaref && dnaref.current) {

                    dnaref.current.resetSelections();
                }
            }}>

            </Interactive>
            {/* <group position={[0, 0, 0]}>
        <mesh>
          <planeBufferGeometry attach="geometry" args={[2, 1]} ></planeBufferGeometry>
          <meshStandardMaterial attach="material" color={"grey"} side={true} />
        </mesh>
        <Text fontSize={0.12} color="black" position={[0, 0, 0.1]}>GAME OVER</Text>
      </group> */}
        </>
    )
}
