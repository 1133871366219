import React from 'react';
import { SERVER_URL } from '../Config';
import { useStageStore } from './StageTemplate';
import "../interface.scss";
import { Link, useNavigate } from 'react-router-dom';
import PretestQuestionModule from './PretestQuestionModule';


function MultipleChoiceQuestionPage() {

    const currentStage = useStageStore(state => state.currentStage)
    const nextStep = useStageStore(state => state.nextStep)
    const prevStep = useStageStore(state => state.prevStep)
    const navigate = useNavigate();
    const answerChoice = useStageStore(state => state.answerChoice)

    const pretestQuestionData = { id: 1, question: currentStage.question2, ordering: 1, answer_choice_id: currentStage.question2_answer, choice: currentStage.question2_choices };

    if (!currentStage)
        return null

    const onAnswered = (index, questionID, answerChoiceID, result) => {

        console.log(" on onAnswered")

        answerChoice(answerChoiceID, result)
        nextStep();
        // navigate("../menu", { replace: true });
    }

    return (
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", height: "100%" }}>
            {/* {currentStage.cover_image ? <img src={SERVER_URL + currentStage.cover_image.url} style={{ width: "85%", maxWidth: "640px", height: "auto", maxHeight: "480px", objectFit: "cover", margin: "20px", borderRadius: "20px" }}></img> : null} */}
            {/* <span className="dnaHeader" style={{ textAlign: "center" }}>{currentStage.name}</span> */}
            {/* <span className="dnaSubHeader" style={{ textAlign: "center", marginBottom: "20px" }}>{currentStage.question1}</span>

            <textarea id="w3review" name="w3review" rows="6" cols="50" style={{ marginBottom: "20px", width: "85%", maxWidth: "640px" }}></textarea> */}
            <PretestQuestionModule pretestQuestionData={pretestQuestionData} onAnswered={onAnswered} ></PretestQuestionModule>
            {/* <div style={{}}>
                <Link to="/menu"><a className="btn grey" style={{ fontSize: "18px" }}>กลับสู่เมนู</a></Link>
            </div> */}
        </div>
    );
}

export default MultipleChoiceQuestionPage;